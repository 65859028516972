import { AppBar, Toolbar, Box, IconButton, Avatar, Typography, Menu, MenuItem } from "@mui/material";
import { useState } from "react";
import { Link } from "react-router-dom";

const Header = ({ isCollapsed, setIsLoggedIn }) => {
    const sidebarWidth = isCollapsed ? 80 : 270;

    const [logo, setLogo] = useState(null);
    const [profileImage, setProfileImage] = useState(null);

    const handleLogout = () => {
        localStorage.removeItem("isLoggedIn");
        setIsLoggedIn(false);
    };

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleFileUploadLogo = (event) => {
        const file = event.target.files[0];
        if (file) {
            const imageURL = URL.createObjectURL(file);
            setLogo(imageURL);
        }
    };

    const handleFileUploadProfile = (event) => {
        const file = event.target.files[0];
        if (file) {
            const imageURL = URL.createObjectURL(file);
            setProfileImage(imageURL);
        }
    };

    return (
        <AppBar
            position="fixed"
            sx={{
                backgroundColor: "#F4F6F8",
                boxShadow: "none",
                borderBottom: "2px solid #9f9f9f",
                width: `calc(100% - ${sidebarWidth}px)`,
                ml: `${sidebarWidth}px`,
                transition: "all 0.3s ease-in-out",
                zIndex: 9,
                height: "60px",
            }}
        >
            <Toolbar
                sx={{
                    minHeight: "50px",
                    display: "flex",
                    justifyContent: "space-between",
                    paddingX: "16px",
                    marginLeft: "29px",
                }}
            >
                <Box display="flex" alignItems="center">
                    <label htmlFor="upload-logo" style={{ display: "flex", alignItems: "center", cursor: "pointer" }}>
                        <IconButton component="span">
                            {logo ? (
                                <img
                                    alt="logo"
                                    src={logo}
                                    style={{
                                        maxWidth: "100px",
                                        maxHeight: "50px",
                                        objectFit: "contain",
                                    }}
                                />
                            ) : (
                                <>
                                    <img
                                        alt="upload-icon"
                                        src={`${process.env.PUBLIC_URL}/assets/icons/navigation/upload-ic.svg`}
                                        width="20px"
                                        height="20px"
                                    />
                                    <Typography
                                        variant="body1"
                                        sx={{
                                            color: "#1976D2",
                                            marginLeft: "8px",
                                            fontWeight: "500",
                                        }}
                                    >
                                        Carga tu logotipo
                                    </Typography>
                                </>
                            )}
                        </IconButton>
                    </label>
                    <input
                        id="upload-logo"
                        type="file"
                        accept="image/*"
                        style={{ display: "none" }}
                        onChange={handleFileUploadLogo}
                    />
                </Box>

                <Box display="flex" alignItems="center" gap="2px" marginRight={"15px"}>
                    <IconButton onClick={handleMenuOpen}>
                        <img
                            alt="add-icon"
                            src={`${process.env.PUBLIC_URL}/assets/icons/navigation/add-cl.svg`}
                            width="25px"
                            height="25px"
                        />
                    </IconButton>
                    <Menu
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleMenuClose}
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "center",
                        }}
                        transformOrigin={{
                            vertical: "top",
                            horizontal: "center",
                        }}
                    >
                        <MenuItem component={Link} to="/budgets/create" onClick={handleMenuClose}>
                            <img
                                alt="add-budget-icon"
                                src={`${process.env.PUBLIC_URL}/assets/icons/navigation/add-presupuesto.svg`}
                                style={{ marginRight: "8px", width: "20px", height: "20px" }}
                            />
                            Crear presupuesto
                        </MenuItem>
                        <MenuItem component={Link} to="/articles/addNew" onClick={handleMenuClose}>
                            <img
                                alt="add-article-icon"
                                src={`${process.env.PUBLIC_URL}/assets/icons/navigation/add-producto.svg`}
                                style={{ marginRight: "8px", width: "20px", height: "20px" }}
                            />
                            Agregar artículo
                        </MenuItem>
                    </Menu>

                    <IconButton onClick={handleLogout}>
                        <img
                            alt="logout-icon"
                            src={`${process.env.PUBLIC_URL}/assets/icons/navigation/logout.svg`}
                            width="25px"
                            height="25px"
                        />
                    </IconButton>

                    <label htmlFor="upload-profile" style={{ cursor: "pointer" }}>
                        <input
                            id="upload-profile"
                            type="file"
                            accept="image/*"
                            style={{ display: "none" }}
                            onChange={handleFileUploadProfile}
                        />
                        <IconButton component="span">
                            {profileImage ? (
                                <img
                                    alt="profile"
                                    src={profileImage}
                                    style={{
                                        width: "40px",
                                        height: "40px",
                                        objectFit: "cover",
                                        borderRadius: "50%",
                                    }}
                                />
                            ) : (
                                <Avatar
                                    sx={{
                                        backgroundColor: "#1976D2",
                                        width: "40px",
                                        height: "40px",
                                        fontSize: "16px",
                                    }}
                                >
                                    FG
                                </Avatar>
                            )}
                        </IconButton>
                    </label>
                </Box>
            </Toolbar>
        </AppBar>
    );
};

export default Header;
