export const loginFields = [
    { 
        name: 'correo', 
        label: 'Correo Electrónico', 
        type: 'email', 
        required: true 
    },
    { 
        name: "password", 
        label: "Contraseña", 
        type: "password", 
        required: true 
    }
]

export const recoverFields = [
    { 
        name: 'correo', 
        label: 'Correo Electrónico', 
        type: 'email', 
        required: true 
    },
]

export const passwordFields = [
    {
        name: 'newPassword',
        label: 'Crear nueva contraseña',
        type: 'password',
        required: true,
    },
    {
        name: 'confirmPassword',
        label: 'Confirmar contraseña',
        type: 'password',
        required: true,
    },
];