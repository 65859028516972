import { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";

import { FormatoArticulosCompuestos } from "../../components/globals/globalForms";
import { precioCompra } from "../../data/forms/articles";

import * as yup from "yup";

import { UseAppConfig } from '../../system/config';
import { getPostData } from '../../system/getData';

const EditRcd = () => {
	// Parámetros de Configuración para cargar los datos requeridos
	// ----------------------------------------------------------------------------------------
		const dataConfig = {
			dbID: "id",
			title: "Actualizar Precio de Compra",
			key: precioCompra,
			module: 'articles',
			description: "Ingrese los datos necesarios para el Precio de Compra de este Articulo",
			colorBtn: "secondary",
			titleBtn: "Actualizar Precio",
			colorBtn2: "info",
			titleBtn2: "Agregar Precio",
			msgSuccess: "Precio de Compra actualizado exitosamente",
			msgError: "Error al actualizar el Precio de Compra",
			getData: "datos/registro",
			sendData: "edita/precioCompra",
			sendData2: "edita/precioVenta"
		}

		const validateSchemas = yup.object().shape({
			precioCompra: yup.number().required('Requerido').typeError('Debe ser un número'),
			precioVenta: yup.number().required('Requerido').typeError('Debe ser un número'),
		});
	// ----------------------------------------------------------------------------------------


	// No Tocar este bloque de código
	// ========================================================================================
		const { id } = useParams();
		const [ initValues, setInitValues ] = useState(null);
		const [ loading, setLoading ] = useState(true);

		const { config, configData } = UseAppConfig();

		useEffect(() => {
			const fetchData = async () => {
				const initVals = await getPostData(`${config.API_BASE_URL}/${dataConfig.getData}`, { id });
				setInitValues(initVals);
				setLoading(false);
			};
			fetchData();
		}, [id, dataConfig.getData]);

		if (loading) {
			return <div>Cargando...</div>;
		}

		return (
			<FormatoArticulosCompuestos
				data={dataConfig}
				initValues={initValues}
				validateSchemas={validateSchemas}
			/>
		);
	// ========================================================================================
}

export default EditRcd;
