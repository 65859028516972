import React from "react";
import { SimpleDataTable } from "../../components/globals/newDataTable";
import { tableHeaders } from "../../data/tableHeaders/users";

const Users = () => {
    const dataConfig = {
        title: "Usuarios",
        module: "users",
        tableHeader: tableHeaders,
        catalogo: "usuarios",
        data: [
            {
                id: 1,
                usuarioDetalle: "Juan Pérez",
                correo: "juan.perez@example.com",
                tipoUsuID: "Administrador",
                status: true,
            },
            {
                id: 2,
                usuarioDetalle: "María López",
                correo: "maria.lopez@example.com",
                tipoUsuID: "Usuario",
                status: false,
            },
            {
                id: 3,
                usuarioDetalle: "Carlos Rodríguez",
                correo: "carlos.rodriguez@example.com",
                tipoUsuID: "Supervisor",
                status: true,
            },
        ],
    };

    return <SimpleDataTable data={dataConfig} />;
};

export default Users;
