
import { tableHeaders } from "../../data/tableHeaders/catalogs";
import { tableContent } from "../../data/catalogList";
import { JSONDataTable } from "../../components/globals/newDataTable";

const Catalogs = () => {
	// Parámetros de Configuración para cargar los datos requeridos
	// ----------------------------------------------------------------------------------------
	const dataConfig = {
		title: "Catálogos",
		module: "catalogs",
		tableHeader: tableHeaders,
		tableContent: tableContent,
		description: "Listado de Catálogos registrado en el sistema",
		endpoint: "listado/historial"
	}
	// ----------------------------------------------------------------------------------------


	// No Tocar este bloque de código
	// ========================================================================================
		return (
			<JSONDataTable data={dataConfig} />
		)
	// ========================================================================================
}

export default Catalogs;