import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
import SendEmail from './SendEmail'; 
import ChangePassword from './ChangePassword'; 

const PasswordRecover = () => {
    const [correo, setCorreo] = useState(null); 
    const [isPasswordStep, setIsPasswordStep] = useState(false); 
    const navigate = useNavigate();

    const handleCorreoEnviado = (email) => {
        setCorreo(email); 
    };

    const avanzarACambiarContrasena = () => {
        if (correo) {
            setIsPasswordStep(true);
        } else {
            alert("Por favor, ingresa un correo válido antes de continuar.");
        }
    };

    return (
        <div>
            {!isPasswordStep ? (
                <>
                    <SendEmail onCorreoEnviado={handleCorreoEnviado} />
                    <Button
                        variant="contained"
                        color="primary"
                        sx={{
                            width: "50%",
                            height: "40px",
                            borderRadius: "5px",
                            backgroundColor: "#1D74D3",
                            marginTop: "20px",
                        }}
                        onClick={avanzarACambiarContrasena}
                    >
                        Aceptar
                    </Button>
                </>
            ) : (
                <ChangePassword correo={correo} />
            )}
        </div>
    );
};

export default PasswordRecover;
