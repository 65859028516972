
export const compoundsHeaders = [
	{
		"field": "clave",
		"headerName": "Clave",
		"flex": 1,
		"headerAlign": "center",
		"align": "center",
		"flex": 1
	},
	{
		"field": "descCorta",
		"headerName": "Descripción",
		"flex": 1,
		"headerAlign": "center",
		"align": "center",
		"flex": 2
	},
	{
		"field": "familia",
		"headerName": "Familia",
		"headerAlign": "center",
		"align": "center",
		"flex": 2
	},
	{
		"field": "unidad",
		"headerName": "Unidad",
		"flex": 1,
		"headerAlign": "center",
		"align": "center",
		"flex": 1
	},
	{
		"field": "precioCompra",
		"headerName": "Precio de Venta",
		"flex": 1,
		"headerAlign": "center",
		"align": "center",
		"flex": 1
	},
	{
		"field": "status",
		"headerName": "Status",
		"flex": 1,
		"headerAlign": "center",
		"align": "center",
		"flex": 1
	},
	{
		"field": "acciones",
		"headerName": "Acciones",
		"flex": 1,
		"headerAlign": "center",
		"align": "center",
		"botons": {
			"detalles": {
				"label": "Detalles",
				"icon": "faEye",
				"class": "secondary",
				"type": "link",
				"action": "cantidad/${row.id}"
			},
			"eliminar": {
				"label": "Eliminar",
				"icon": "faTrash",
				"class": "error",
				"type": "link",
				"action": "edit/${row.id}"
			}
		}
	}
];
