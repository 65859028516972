
export const tableHeaders = [
	{
		"field": "nombre",
		"headerName": "Nombre del Catálogo",
		"headerAlign": "center",
		"align": "center",
		"flex": 1
	},
	{
		"field": "descripciones",
		"headerName": "Descripción",
		"headerAlign": "center",
		"align": "center",
		"flex": 2
	},
	{
		"field": "acciones",
		"headerName": "Acciones",
		"headerAlign": "center",
		"align": "left",
		"flex": 6
	}
];
