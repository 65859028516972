import { useLocation } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { GeneraFormularioCambioContrasena } from "../globals/globalForms";
import { passwordFields } from "../../data/forms/login";
import * as yup from "yup";
import { UseAppConfig } from '../../system/config';
import { getPostData } from '../../system/getData';

const ChangePassword = () => {
    const location = useLocation();
    const { enqueueSnackbar } = useSnackbar();
    const { config } = UseAppConfig();

    // Leer el token desde la URL
    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get('token');

    if (!token) {
        return <div>Error: No se proporcionó un token válido.</div>;
    }

    const dataConfig = {
        title: "Cambia tu contraseña",
        description: "Crea una nueva contraseña para tu cuenta.",
        titleBtn: "Cambiar Contraseña",
        fields: passwordFields, 
    };

    const initValues = {
        newPassword: "",
        confirmPassword: "",
    };

    const validateSchemas = yup.object().shape({
        newPassword: yup
            .string()
            .min(8, "La contraseña debe tener al menos 8 caracteres")
            .matches(
                /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
                "Debe incluir una mayúscula, una minúscula, un número y un carácter especial."
            )
            .required("Inserte su nueva contraseña"),
        confirmPassword: yup
            .string()
            .oneOf([yup.ref("newPassword"), null], "Las contraseñas no coinciden")
            .required("Campo requerido"),
    });

    const handleFormSubmit = async (values) => {
        const payload = {
            token, 
            nuevaPassword: values.newPassword,
        };

        try {
            const response = await getPostData(`${config.API_BASE_URL}/cambiarPassword`, payload);

            if (response && response.message === "Contraseña cambiada exitosamente") {
                enqueueSnackbar("Contraseña actualizada exitosamente", { variant: "success" });
            } else {
                enqueueSnackbar(response.message || "Error al actualizar la contraseña", {
                    variant: "error",
                });
            }
        } catch (error) {
            enqueueSnackbar("Error al cambiar la contraseña", { variant: "error" });
        }
    };

    return (
        <GeneraFormularioCambioContrasena
            data={dataConfig}
            initValues={initValues}
            validateSchemas={validateSchemas}
            handleFormSubmit={handleFormSubmit}
        />
    );
};

export default ChangePassword;
