import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { ExpandLess, ExpandMore, Save, Cancel } from '@mui/icons-material';

import { Grid, Box, useTheme, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Collapse, IconButton, Button, Modal, Switch, Typography, TextField } from '@mui/material';

import { useSnackbar } from 'notistack';

import { DataGrid } from "@mui/x-data-grid";
import { styled } from '@mui/material/styles';

import { tokens } from "../../data/tokens";
import { Header } from "../basics/Header";
import { UseAppConfig } from '../../system/config';
import { getPostData, getDataArray } from '../../system/getData';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBox, faCopy, faPlus, faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';

import { FormRcd } from "../catalogs/addCatalogs";
import { Link } from "react-router-dom";
import  SearchFilters  from './searchFilters';

import { CreateSection, EditSection, AddArticles, CreateProject } from "../../components/projects/addElements";

import '../../theme/project.css';

const formatCurrency = (value) => {
    return new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(value);
};

export const JSONDataTable = ({ data }) => {
    const navigate = useNavigate();
    const handleEdit = (field) => {
        navigate(`/${data.module}/edit/${field}`);
    };
    return (
        <Grid p={4} container justifyContent="center" className="row-printable">
            <Grid item xs={12} md={12}>
                <Header title={data.title} subtitle={data.description} />
                <Paper elevation={3} className="panel">
                    <Box mt={4}>
                        <TableContainer component={Paper}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        {data.tableHeader.map((item, index) => (
                                            <TableCell
                                                key={index}
                                                sx={{ backgroundColor: '#052c65', color: 'white' }}
                                            >
                                                {item.headerName}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {data.tableContent.map((item, index) => (
                                        <TableRow key={index}>
                                            <TableCell
                                                sx={{ backgroundColor: index % 2 === 0 ? '#f1f2f3' : '#ffffff' }}
                                            >
                                                {item.name}
                                            </TableCell>
                                            <TableCell
                                                align="left"
                                                sx={{ backgroundColor: index % 2 === 0 ? '#f1f2f3' : '#ffffff' }}
                                            >
                                                {item.description || 'Sin descripción'}
                                            </TableCell>
                                            <TableCell
                                                align="center"
                                                sx={{ backgroundColor: index % 2 === 0 ? '#f1f2f3' : '#ffffff' }}
                                            >
                                                <Button
                                                    variant="contained"
                                                    color="warning"
                                                    size="small"
                                                    sx={{ mr: 1 }}
                                                    onClick={() => handleEdit(item.field)}
                                                >
                                                    Editar
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                </Paper>
            </Grid>
        </Grid>
    );
};

export const SimpleDataTable = ({ data }) => {
    const [dataTable, setDataTable] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const rowsPerPage = 6;
    const totalPages = Math.ceil(filteredData.length / rowsPerPage);
    const paginatedData = filteredData.slice(
        (currentPage - 1) * rowsPerPage,
        currentPage * rowsPerPage
    );

    useEffect(() => {
        // Datos de prueba
        const testData = [
            {
                id: 1,
                usuarioDetalle: "Juan Pérez",
                correo: "juan.perez@example.com",
                tipoUsuID: "Administrador",
                status: 1,
            },
            {
                id: 2,
                usuarioDetalle: "María López",
                correo: "maria.lopez@example.com",
                tipoUsuID: "Gerente",
                status: 0,
            },
            {
                id: 3,
                usuarioDetalle: "Julio Rodríguez",
                correo: "julio.rodriguez@example.com",
                tipoUsuID: "Colaborador",
                status: 1,
            },
            {
                id: 4,
                usuarioDetalle: "Alberto Perez",
                correo: "alberto.perez@example.com",
                tipoUsuID: "Administrador",
                status: 0,
            },
            {
                id: 5,
                usuarioDetalle: "Emma Lopez",
                correo: "emma.lopez@example.com",
                tipoUsuID: "Gerente",
                status: 1,
            },
            {
                id: 6,
                usuarioDetalle: "Emma Lopez",
                correo: "emma.lopez@example.com",
                tipoUsuID: "Gerente",
                status: 1,
            },            {
                id: 7,
                usuarioDetalle: "Emma Lopez",
                correo: "emma.lopez@example.com",
                tipoUsuID: "Gerente",
                status: 1,
            },
            {
                id: 8,
                usuarioDetalle: "Emma Lopez",
                correo: "emma.lopez@example.com",
                tipoUsuID: "Gerente",
                status: 1,
            },            {
                id: 9,
                usuarioDetalle: "Emma Lopez",
                correo: "emma.lopez@example.com",
                tipoUsuID: "Gerente",
                status: 1,
            },            {
                id: 10,
                usuarioDetalle: "Emma Lopez",
                correo: "emma.lopez@example.com",
                tipoUsuID: "Gerente",
                status: 1,
            },
        ];

        // Transformar datos de prueba
        const formattedData = testData.map((row) => ({
            ...row,
            usuarioDetalle: (
                <Box display="flex" alignItems="center" gap="10px">
                    <Box
                        sx={{
                            width: "40px",
                            height: "40px",
                            backgroundColor: "#1976d2",
                            borderRadius: "50%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            color: "#fff",
                            fontSize: "14px",
                            fontWeight: "bold",
                            textTransform: "uppercase",
                        }}
                    >
                        {row.usuarioDetalle.charAt(0)}
                    </Box>
                    <Typography variant="body1">{row.usuarioDetalle}</Typography>
                </Box>
            ),
            status: row.status === 1 ? (
                <Switch defaultChecked color="primary" />
            ) : (
                <Switch color="primary" />
            ),
            acciones: (
                <Box display="flex" gap="8px">
                    <Link to={`/delete/${row.id}`}>
                        <img
                            src={`${process.env.PUBLIC_URL}/assets/icons/navigation/eliminar.svg`}
                            alt="Eliminar"
                            width="20"
                            height="20"
                            style={{ cursor: "pointer" }}
                        />
                    </Link>
                    <Link to={`/edit/${row.id}`}>
                        <img
                            src={`${process.env.PUBLIC_URL}/assets/icons/navigation/editar.svg`}
                            alt="Editar"
                            width="20"
                            height="20"
                            style={{ cursor: "pointer" }}
                        />
                    </Link>
                </Box>
            ),
        }));

        setDataTable(formattedData);
        setFilteredData(formattedData);
    }, []);

    const handleFilterChange = (selectedRoles) => {
        if (selectedRoles.length > 0) {
            const filtered = dataTable.filter((row) =>
                selectedRoles.includes(row.tipoUsuID)
            );
            setFilteredData(filtered);
        } else {
            setFilteredData(dataTable); // Mostrar todos si no hay roles seleccionados
        }
    };
    

    const handleSearch = (searchTerm) => {
        if (searchTerm) {
            const filtered = dataTable.filter((row) =>
                Object.values(row).some(
                    (fieldValue) =>
                        fieldValue && fieldValue.toString().toLowerCase().includes(searchTerm.toLowerCase())
                )
            );
            setFilteredData(filtered);
        } else {
            setFilteredData(dataTable);
        }
    };

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    return (
        <Box sx={{ backgroundColor: "#F4F6F8", padding: "20px" }}>
            <Typography
                variant="h3"
                sx={{
                    fontWeight: "bold",
                    marginBottom: "24px",
                    textAlign: "left",
                }}
            >
                {data.title}
            </Typography>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: "30px",
                }}
            >
                <Box sx={{ display: "flex", alignItems: "center", gap: "16px" }}>
                    <SearchFilters catalogo={data.catalogo} onSearch={handleSearch} onFilterChange={handleFilterChange} />
                </Box>

                <Button
                    variant="contained"
                    sx={{
                        textTransform: "none",
                        backgroundColor: "#1976d2",
                        color: "#fff",
                        fontSize: "15px",
                        gap: "8px",
                        "&:hover": {
                            backgroundColor: "#155fa0",
                        },
                    }}
                >
                    <img
                        alt="editar-icon"
                        src={`${process.env.PUBLIC_URL}/assets/icons/navigation/add-cl.svg`}
                        width="20px"
                        height="20px"
                        style={{ cursor: "pointer", filter: "brightness(100)" }}
                    />
                    Agregar
                </Button>
            </Box>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            {data.tableHeader.map((header) => (
                                <TableCell
                                    key={header.field}
                                    align="center"
                                    sx={{ fontSize: "14px", fontWeight: "bold", padding: "10px" }}
                                >
                                    {header.headerName}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {paginatedData.length > 0 ? (
                            paginatedData.map((row, index) => (
                                <TableRow key={index} sx={{ height: "36px" }}>
                                    {data.tableHeader.map((header) => (
                                        <TableCell
                                            key={header.field}
                                            align="center"
                                            sx={{ fontSize: "14px", padding: "10px" }}
                                        >
                                            {row[header.field]}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            ))
                        ) : (
                            <TableRow>
                                <TableCell colSpan={data.tableHeader.length} align="center">
                                    No hay datos disponibles.
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "16px",
                    gap: "8px",
                }}
            >
                <Button
                    onClick={handlePreviousPage}
                    disabled={currentPage === 1}
                    sx={{
                        border: "1px solid #ccc",
                        borderRadius: "8px",
                        padding: "6px 12px",
                        textTransform: "none",
                        backgroundColor: currentPage === 1 ? "#f9f9f9" : "#fff",
                        "&:hover": {
                            backgroundColor: "#f5f5f5",
                        },
                        "&.Mui-disabled": {
                            color: "#aaa",
                        },
                    }}
                >
                    <img
                        src={`${process.env.PUBLIC_URL}/assets/icons/navigation/arrow-left.svg`}
                        alt="Flecha izquierda"
                        width="16"
                        height="16"
                    />
                    Anterior
                </Button>
                {Array.from({ length: totalPages }, (_, index) => {
                    const pageNumber = index + 1;
                    return (
                        <Box
                            key={pageNumber}
                            onClick={() => setCurrentPage(pageNumber)}
                            sx={{
                                cursor: "pointer",
                                width: "35px",
                                height: "35px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                borderRadius: "4px",
                                backgroundColor: currentPage === pageNumber ? "#000" : "transparent",
                                color: currentPage === pageNumber ? "#fff" : "#000",
                                border: currentPage === pageNumber ? "none" : "1px solid #ccc",
                            }}
                        >
                            {pageNumber}
                        </Box>
                    );
                })}
                <Button
                    onClick={handleNextPage}
                    disabled={currentPage === totalPages}
                    sx={{
                        border: "1px solid #ccc",
                        borderRadius: "8px",
                        padding: "6px 12px",
                        textTransform: "none",
                        backgroundColor: currentPage === totalPages ? "#f9f9f9" : "#fff",
                        "&:hover": {
                            backgroundColor: "#cdeeff",
                        },
                        "&.Mui-disabled": {
                            color: "#aaa",
                        },
                    }}
                >
                    Siguiente
                    <img
                        src={`${process.env.PUBLIC_URL}/assets/icons/navigation/arrow-right.svg`}
                        alt="Flecha derecha"
                        width="16"
                        height="16"
                    />
                </Button>
            </Box>
        </Box>
    );
};


export const DataTablePerID = ({ data, section }) => {
    const { id } = useParams();
    const { config } = UseAppConfig();
    const [dataTable, setDataTable] = useState({});

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    const [modalOpen, setModalOpen] = useState(false);
    const [modalSectionName, setModalSectionName] = useState('');
    const [modalSectionValue, setModalSectionValue] = useState('');
    const [modalSection, setModalSection] = useState(false);

    const handleModalOpen = (catalogValue, catalogName, section) => {
        setModalSectionValue(catalogValue);
        setModalSectionName(catalogName);
        setModalSection(section);
        setModalOpen(true);
    };

    const handleModalClose = () => {
        setModalOpen(false);
    };

    const getColumns = (catalog) => {
        if (!catalog || !catalog.columns) return [];
        const columns = [
            ...(catalog.columns || []),
            {
                field: 'edit',
                headerName: 'Editar',
                width: 100,
                headerAlign: 'center',
                align: 'center',
                renderCell: (params) => (
                    <Box display="flex" justifyContent="center" width="100%">
                        <Button
                            style={{ width: '10px', minWidth: '10px' }}
                            variant="contained"
                            color="warning"
                            onClick={() => handleModalOpen(id, params.row.id, 'edit')}
                        >
                            <FontAwesomeIcon icon={faEdit} />
                        </Button>
                    </Box>
                ),
            },
        ];
        return columns;
    };

    const fetchDataTable = async (field) => {
        try {
            const jsonData = await getPostData(`${config.API_BASE_URL}/${data.endpoint}/${field}`, {});

            let formattedData = jsonData.map(row => ({
                ...row,
                status: row.status === 1 ? 'Activo' : 'Desactivado',
                module: row.tipo === 'Simple' ? 'articles' : 'compounds'
            }));

            setDataTable(prevState => ({ ...prevState, [field]: formattedData }));
        } catch (error) {
            console.error('Error al obtener los datos:', error);
            setError(`No ha sido posible obtener los datos del registro: ${field}`);
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            if (id) {
                setIsLoading(true);
                await fetchDataTable(id);
                setIsLoading(false);
            }
        };
        fetchData();
    }, [id]);

    if (isLoading) {
        return <div>Cargando...</div>;
    }

    if (error) {
        return <div>{error}</div>;
    }

    return (
        <Box m="0px">
            <Box
                maxWidth="100%"
                mx="auto"
                mt="-25px"
                sx={{
                    "& .MuiTable-root": { border: "none" },
                    "& .MuiTableCell-root": { borderBottom: "none" },
                    "& .MuiTableHead-root": { backgroundColor: colors.primary[500], borderBottom: "none" },
                    "& .MuiTableCell-head": { color: colors.grey[900] },
                    "& .MuiTableContainer-root": { backgroundColor: colors.primary[900] },
                }}
            >
                <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                    <Typography variant="h6">Registros del Catálogo</Typography>
                    <Button
                        variant="contained"
                        color="info"
                        onClick={() => handleModalOpen(id, section.name, 'new')}
                    >
                        <FontAwesomeIcon icon={faPlus} /> Agregar
                    </Button>
                </Box>
                <DataGrid
                    rows={dataTable[id] || []}
                    columns={getColumns(section) || []}
                    getRowClassName={(params) =>
                        params.indexRelativeToCurrentPage % 2 === 0 ? 'fila-par' : 'fila-impar'
                    }
                    disableColumnMenu
                    disableColumnSelector
                    hideFooter
                    className="datos-tabla"
                    autoHeight
                    sx={{
                        '& .MuiDataGrid-columnHeaders': {
                            backgroundColor: '#052c65',
                            color: '#fff',
                        }
                    }}
                />
            </Box>
            <Modal
                open={modalOpen}
                onClose={handleModalClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Paper
                    elevation={3}
                    sx={{
                        width: '100%',
                        maxWidth: '700px',
                        margin: '0 auto',
                        padding: 2,
                    }}
                >
                    {modalSection === 'new' ? (
                        <FormRcd secValue={modalSectionValue} secName={modalSectionName} />
                    ) : (
                        <FormRcd secValue={modalSectionValue} regID={modalSectionName} />
                    )}
                </Paper>
            </Modal>
        </Box>
    );
};

export const ExpandableDataTable = ({ data }) => {
    const { config } = UseAppConfig();
    const [dataTable, setDataTable] = useState([]);
    const [loading, setLoading] = useState(true);

    const { enqueueSnackbar } = useSnackbar();

    const buildHierarchy = (flatData) => {
        const dataMap = {};
        const result = [];

        flatData.forEach(item => {
            dataMap[item.id] = { ...item, cat: [] };
        });

        flatData.forEach(item => {
            const { id, parent_id } = item;
            if (parent_id === null || parent_id === 'null') {
                result.push(dataMap[id]);
            } else {
                if (dataMap[parent_id]) {
                    dataMap[parent_id].cat.push(dataMap[id]);
                } else {
                    console.warn(`Subcategoría con ID ${id} tiene un parent_id (${parent_id}) que no existe.`);
                }
            }
        });
        return result;
    };

    useEffect(() => {
        const fetchDataTable = async () => {
            try {
                const response = await getDataArray(`${config.API_BASE_URL}/${data.contentList}`, { proyID: data.id });
                console.log("Datos planos recibidos:", response);
                const hierarchicalData = buildHierarchy(response);
                console.log("Datos jerarquizados:", hierarchicalData);
                setDataTable(hierarchicalData);
            } catch (error) {
                console.error('Error fetching categories:', error);
                enqueueSnackbar('Error al cargar las categorías', { variant: 'error' });
            }
        };

        fetchDataTable();
    }, [data.id, data.getData]);

    return (
        <TableContainer component={Paper}>
            <Table>
                <TableHead>
                    <TableRow>
                        {data.tableHeader.map((header, index) => (
                            <TableCell
                                key={index}
                                sx={{
                                    backgroundColor: '#052c65',
                                    color: 'white',
                                    width: header.width,
                                    textAlign: header.field === 'total' ? 'right' : header.align || 'center',
                                }}
                            >
                                {header.headerName}
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {dataTable.length > 0 ? (
                        dataTable.map((row, index) => (
                            <ExpandableData key={index} data={row} level={0} />
                        ))
                    ) : (
                        <TableRow>
                            <TableCell colSpan={data.tableHeader ? data.tableHeader.length : 1} align="center">
                                No hay datos para mostrar.
                            </TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

const ExpandableData = ({ data, level = 0 }) => {
    const [isExpanded, setIsExpanded] = useState(false);

    const handleToggle = () => {
        setIsExpanded(!isExpanded);
    };

    const IconsDefault = ({ tipo, icon = '' }) => {
        const iconMap = {
            'Categoría': '/assets/icons/openBox.png',
            'Simple': '/assets/icons/barCode.png',
            'Compuesto': '/assets/icons/QR.png',
        };

        const iconSrc = icon.length > 0 ? `/assets/icons/${icon}` : iconMap[tipo];

        return (
            <TableCell sx={{ textAlign: 'center', width: '7%' }}>
                {iconSrc ? (
                    <img src={iconSrc} width="25px" alt="" />
                ) : (
                    tipo || 'Tipo'
                )}
            </TableCell>
        );
    };

    const ColorsDefault = ({ nombre, tipo }) => {
        const styleConfig = {
            'Categoría': { color: 'black', fontSize: '16px', fontWeight: 'bold', textTransform: 'uppercase' },
            'Compuesto': { color: 'green', fontSize: '15px', fontWeight: 'normal' },
            'Simple': { color: 'blue', fontSize: '14px', fontWeight: 'normal' },
        };

        const defaultStyle = { color: 'red', fontSize: '14px', fontWeight: 'normal' };
        const appliedStyle = styleConfig[tipo] || defaultStyle;

        return <span style={appliedStyle}>{nombre}</span>;
    };

    const botonesConfig = {
        'Categoría': [
            { label: 'Agregar Sub-Categoría', color: 'success', icon: faBox },
            { label: 'Agregar Artículos', color: 'info', icon: faCopy },
            { label: 'Editar', color: 'warning', icon: faEdit },
            { label: 'Eliminar', color: 'error', icon: faTrash },
        ],
        'Compuesto': [
            { label: 'Eliminar', color: 'error', icon: faTrash },
        ],
        'Simple': [
            { label: 'Eliminar', color: 'error', icon: faTrash },
        ],
    };

    const commonCellStyle = {
        textAlign: 'center',
        width: '10%', // Ajusta el ancho según tus necesidades
    };

    const botones = botonesConfig[data.tipo] || [];

    return (
        <>
            {data && (
                <TableRow sx={{ '& > *': { padding: '4px 4px' } }}>
                    <TableCell
                        sx={{
                            borderBottom: 'none',
                            position: 'relative',
                            backgroundColor: '#f1f2f3',
                            paddingLeft: `${level * 2}%`,
                            width: '40%',
                        }}
                    >
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            {(data.art && data.art.length > 0) || (data.cat && data.cat.length > 0) ? (
                                <IconButton sx={{ marginRight: 1, cursor: 'pointer' }} onClick={handleToggle}>
                                    {isExpanded ? <ExpandLess /> : <ExpandMore />}
                                </IconButton>
                            ) : (
                                <span style={{ marginLeft: '12px', marginRight: '15px', fontSize: '20px' }}>→</span>
                            )}
                            <Typography sx={{ display: 'flex', alignItems: 'center', fontSize: '14px' }}>
                                <Box component="span" sx={{ marginLeft: 1 }}>
                                    <ColorsDefault nombre={data.nombre} tipo={data.tipo} />
                                </Box>
                            </Typography>
                        </Box>
                    </TableCell>

                    <TableCell sx={{ textAlign: 'center', width: '10%' }}>
                        {botones.map((button, index) => (
                            <Button
                                key={index}
                                variant="contained"
                                color={button.color}
                                title={button.label}
                                style={{ minWidth: '25px', margin: '2px' }}
                            >
                                <FontAwesomeIcon icon={button.icon} />
                            </Button>
                        ))}
                    </TableCell>

                    <IconsDefault tipo={data.tipo} icon={data.icono} />
                    {Object.keys(data)
                        .filter((field) =>
                            field !== 'id' &&
                            field !== 'parent_id' &&
                            field !== 'nombre' &&
                            field !== 'tipo' &&
                            field !== 'icono' &&
                            field !== 'cat' &&
                            field !== 'art'
                        )
                        .map((field, index) => (
                            <TableCell
                                key={index}
                                sx={{
                                    ...commonCellStyle,
                                    textAlign: index === 0 ? 'left' : 'center',
                                    width: index === 0 ? '10%' : '10%'
                                }}
                            >
                                {data[field] || field}
                            </TableCell>
                        ))
                    }
                </TableRow>
            )}

            {data.cat && (
                <TableRow>
                    <TableCell style={{ padding: 0, borderBottom: 'none' }} colSpan={10}>
                        <Collapse in={isExpanded} timeout="auto" unmountOnExit>
                            <Table size="small">
                                <TableBody>
                                    {data.cat.map((child, index) => (
                                        <ExpandableData key={index} data={child} level={level + 1} />
                                    ))}
                                </TableBody>
                            </Table>
                        </Collapse>
                    </TableCell>
                </TableRow>
            )}

            {data.art && (
                <TableRow>
                    <TableCell style={{ padding: 0, borderBottom: 'none' }} colSpan={10}>
                        <Collapse in={isExpanded} timeout="auto" unmountOnExit>
                            <Table size="small">
                                <TableBody>
                                    {data.art.map((child, index) => (
                                        <ExpandableData key={index} data={child} level={level + 1} />
                                    ))}
                                </TableBody>
                            </Table>
                        </Collapse>
                    </TableCell>
                </TableRow>
            )}
        </>
    );
}

