import { useState, useEffect } from 'react';
import { GeneraFormularioSimple } from "../../components/globals/globalForms";
import { usersFields } from "../../data/forms/users";
import { useParams } from "react-router-dom";

import * as yup from "yup";

import { UseAppConfig } from '../../system/config';
import { getPostData } from '../../system/getData';

const EditRcd = () => {
	// Parámetros de Configuración para cargar los datos requeridos
	// ----------------------------------------------------------------------------------------
		const dataConfig = {
			dbID: "id",
			title: "Editar Usuario",
			key: usersFields,
			section: 'users',
			module: 'users',
			description: "Ingrese los datos necesarios para editar los datos de este Usuario",
			titleBtn: "Editar Usuario",
			colorBtn: "secondary",
			msgSuccess: "Usuario editado exitosamente",
			msgError: "Error al editar el usuario",
			getData: "detalle/usuario",
			sendData: "edita/usuario"
		}

		const validateSchemas = yup.object().shape({
			nombre: yup.string().required('Requerido'),
			apPat: yup.string().required('Requerido'),
			apMat: yup.string().required('Requerido'),
			correo: yup.string().email('Correo inválido').required('Requerido'),
			telefono1: yup.string(),
			telefono2: yup.string(),
			direccion: yup.string(),
			tipoUsuID: yup.number().required('Requerido'),
		});
	// ----------------------------------------------------------------------------------------


	// No Tocar este bloque de código
	// ========================================================================================
		const { id } = useParams();
		const [ initValues, setInitValues ] = useState(null);
		const [ loading, setLoading ] = useState(true);

		const { config, configData } = UseAppConfig();

		useEffect(() => {
			const fetchData = async () => {
				const initVals = await getPostData(`${config.API_BASE_URL}/${dataConfig.getData}`, { id });
				setInitValues(initVals);
				setLoading(false);
			};
			fetchData();
		}, [id, dataConfig.getData]);

		if (loading) {
			return <div>Cargando...</div>;
		}

		return (
			<GeneraFormularioSimple
				data={dataConfig}
				initValues={initValues}
				validateSchemas={validateSchemas}
			/>
		);
	// ========================================================================================
}

export default EditRcd;
