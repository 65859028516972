import { useState } from "react";
import { ProSidebar, Menu, MenuItem } from "react-pro-sidebar";
import { Box, IconButton } from "@mui/material";
import { Link } from "react-router-dom";
import "react-pro-sidebar/dist/css/styles.css";
import dataMenu from "../../data/menu";

const Item = ({ title, to, icon, selected, setSelected }) => {
    return (
        <MenuItem
            active={selected === title}
            onClick={() => setSelected(title)}
            style={{
                margin: "2px 10px",
                display: "flex",
                alignItems: "center",
                gap: "10px",
                fontSize: "14px",
                fontWeight: "normal",
            }}
            icon={
                <img
                    src={process.env.PUBLIC_URL + icon}
                    alt={title}
                    style={{ width: 25, height: 25 }}
                />
            }
        >
            {title}
            <Link to={to} />
        </MenuItem>
    );
};

const Sidebar = ({ isCollapsed, setIsCollapsed }) => {
    const [selected, setSelected] = useState("Dashboard");

    return (
        <Box
            sx={{
                position: "fixed",
                height: "100%",
                width: isCollapsed ? "80px" : "250px", 
                backgroundColor: "#fff",
                borderRadius: "0 10px 0 0",
                zIndex: 10, 
                transition: "width 0.3s ease-in-out", 
                "& .pro-sidebar-inner": {
                    backgroundColor: "#fff !important",
                },
                "& .pro-icon-wrapper": {
                    backgroundColor: "transparent !important",
                },
                "& .pro-inner-item": {
                    padding: "0 12px !important",
                    color: "#333",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "start",
                    width: "100%",
                    height: "50px",
                    borderRadius: "10px",
                },
                "& .pro-menu-item.active": {
                    backgroundColor: "#d8e9fd",
                    color: "#333 !important",
                    borderRadius: "10px",
                },
                "& .pro-inner-item:hover": {
                    backgroundColor: "#f1f1f1",
                    color: "#333 !important",
                    cursor: "pointer",
                    borderRadius: "10px",
                },
            }}
        >
            <ProSidebar collapsed={isCollapsed}>
                <Menu iconShape="square">
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            height: "60px",
                            padding: "0 20px",
                            position: "relative",
                        }}
                    >
                        <IconButton
                            onClick={() => setIsCollapsed(!isCollapsed)}
                            sx={{
                                position: "absolute",
                                left: "20px",
                                width: "40px",
                                height: "40px",
                                transform: "translateY(-50%)",
                                top: "50%",
                                transition: "transform 0.3s ease",
                            }}
                        >
                            <img
                                src={
                                    isCollapsed
                                        ? `${process.env.PUBLIC_URL}/assets/icons/navigation/arrow-right.svg`
                                        : `${process.env.PUBLIC_URL}/assets/icons/navigation/arrow-left.svg`
                                }
                                alt="Toggle Sidebar"
                                style={{ width: 25, height: 25 }}
                            />
                        </IconButton>
                        {!isCollapsed && (
                            <img
                                src={`${process.env.PUBLIC_URL}/assets/icons/navigation/PU-logo.svg`}
                                alt="PU Logo"
                                style={{
                                    height: "25px",
                                    pointerEvents: "none",
                                    marginLeft: "50px",
                                }}
                            />
                        )}
                    </Box>
                    <Box>
                        {dataMenu.map((menuItem) => (
                            <Item
                                key={menuItem.id}
                                title={menuItem.title}
                                to={menuItem.dir}
                                icon={menuItem.icon}
                                selected={selected}
                                setSelected={setSelected}
                            />
                        ))}
                    </Box>
                </Menu>
            </ProSidebar>
        </Box>
    );
};

export default Sidebar;
