export const tableHeaders = [
    {
        field: "nombre", 
        headerName: "Organización",
        headerAlign: "center",
        align: "center",
        flex: 2,
    },
    {
        field: "factorUtilidad",
        headerName: "Factor Utilidad",
        headerAlign: "center",
        align: "center",
        flex: 2,
    },
    {
        field: "correo", 
        headerName: "Correo Electrónico",
        headerAlign: "center",
        align: "center",
        flex: 2,
    },
    {
        field: "giro",
        headerName: "Giro",
        headerAlign: "center",
        align: "center",
        flex: 2,
    },
    {
        field: "creacion",
        headerName: "Creación",
        headerAlign: "center",
        align: "center",
        flex: 1,
    },
    {
        field: "activo",
        headerName: "Activo",
        headerAlign: "center",
        align: "center",
        flex: 1,
        isSwitch: true,
    },
    {
        field: "acciones",
        headerName: "",
        headerAlign: "center",
        align: "center",
        flex: 1,
        botons: {
            eliminar: {
                label: "Eliminar",
                class: "error",
                type: "link",
                action: "delete/${row.id}",
            },
            editar: {
                label: "Editar",
                class: "primary",
                type: "link",
                action: "edit/${row.id}",
            },
        },
    },
];
