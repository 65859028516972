import React, { useState } from "react";
import { Box, TextField, Button, Menu, MenuItem, Checkbox, FormControlLabel, Typography } from "@mui/material";

const SearchFilters = ({ catalogo, onSearch, onFilterChange }) => {
    const [searchTerm, setSearchTerm] = useState("");
    const [checkedRoles, setCheckedRoles] = useState({
        Administrador: false,
        Gerente: false,
        Colaborador: false,
    });
    const [anchorEl, setAnchorEl] = useState(null);

    const handleSearchChange = (event) => {
        const value = event.target.value;
        setSearchTerm(value);
        if (onSearch) onSearch(value);
    };

    const handleFilterChange = (role) => {
        const updatedRoles = {
            ...checkedRoles,
            [role]: !checkedRoles[role],
        };
        setCheckedRoles(updatedRoles);

        // Filtrar los roles seleccionados
        const selectedRoles = Object.keys(updatedRoles).filter((key) => updatedRoles[key]);
        if (onFilterChange) onFilterChange(selectedRoles);
    };

    const handleDropdownOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleDropdownClose = () => {
        setAnchorEl(null);
    };

    const selectedFiltersCount = Object.values(checkedRoles).filter((value) => value).length;

    return (
        <Box
            sx={{
                display: "flex",
                alignItems: "center",
                gap: "20px",
            }}
        >
            {/* Textfield for search */}
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "center",
                    width: "350px",
                    backgroundColor: "#fff",
                    borderRadius: "8px",
                    border: "1px solid #ccc",
                    padding: "5px 12px",
                    gap: "10px",
                }}
            >
                <img
                    alt="buscar-icon"
                    src={`${process.env.PUBLIC_URL}/assets/icons/navigation/buscar.svg`}
                    width="20px"
                    height="20px"
                    style={{ cursor: "pointer" }}
                />
                <TextField
                    fullWidth
                    placeholder="Buscar cliente, correo electrónico"
                    variant="standard"
                    value={searchTerm}
                    onChange={handleSearchChange}
                    InputProps={{
                        disableUnderline: true,
                    }}
                    sx={{
                        "& .MuiInputBase-root": {
                            margin: 0,
                            padding: 0,
                            backgroundColor: "transparent",
                        },
                        "& .MuiInput-underline:before, & .MuiInput-underline:after": {
                            display: "none",
                        },
                    }}
                />
            </Box>

            <Button
    variant="outlined"
    onClick={handleDropdownOpen}
    sx={{
        textTransform: "none",
        backgroundColor: "#fff",
        borderColor: "#ccc",
        width: "120px", // Tamaño fijo del botón
        height: "41px", // Altura fija del botón
        fontSize: "15px", // Tamaño del texto
        display: "flex", // Usar flexbox para manejar el diseño interno
        justifyContent: "center", // Centrar el contenido horizontalmente
        alignItems: "center", // Centrar el contenido verticalmente
        position: "relative", // Permite posicionar el contador de forma absoluta
        padding: "0 12px", // Relleno interno
    }}
>
    <Box
        sx={{
            display: "flex",
            alignItems: "center",
            gap: "8px", // Espacio entre el ícono y el texto
            position: "relative", // Permite mantener el texto e ícono centrados
            transition: "transform 0.2s ease", // Transición suave
            transform: selectedFiltersCount > 0 ? "translateX(-10px)" : "translateX(0)", // Desplaza dinámicamente
        }}
    >
        <img
            alt="filtro-icon"
            src={`${process.env.PUBLIC_URL}/assets/icons/navigation/filtro.svg`}
            width="20px"
            height="20px"
            style={{ cursor: "pointer" }}
        />
        Filtrar
    </Box>
    {selectedFiltersCount > 0 && (
        <Box
            sx={{
                backgroundColor: "#1e88e5", // Fondo azul
                color: "#fff", // Texto blanco
                borderRadius: "50%", // Forma circular
                width: "20px", // Ancho del círculo
                height: "20px", // Alto del círculo
                display: "flex", // Flexbox para centrar contenido
                alignItems: "center", // Centrar verticalmente
                justifyContent: "center", // Centrar horizontalmente
                fontSize: "12px", // Tamaño del texto
                fontWeight: "bold", // Texto en negrita
                position: "absolute", // Posición absoluta dentro del botón
                right: "10px", // Espaciado desde el borde derecho
            }}
        >
            {selectedFiltersCount}
        </Box>
    )}
</Button>





            <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleDropdownClose}>
                <MenuItem>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={checkedRoles.Administrador}
                                onChange={() => handleFilterChange("Administrador")}
                            />
                        }
                        label="Administrador"
                    />
                </MenuItem>
                <MenuItem>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={checkedRoles.Gerente}
                                onChange={() => handleFilterChange("Gerente")}
                            />
                        }
                        label="Gerente"
                    />
                </MenuItem>
                <MenuItem>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={checkedRoles.Colaborador}
                                onChange={() => handleFilterChange("Colaborador")}
                            />
                        }
                        label="Colaborador"
                    />
                </MenuItem>
            </Menu>
        </Box>
    );
};

export default SearchFilters;
