
export const tableHeaders = [
	{
		"field": "usuarioID",
		"headerName": "Usuario",
		"headerAlign": "center",
		"align": "center",
		"flex": 1
	},
	{
		"field": "seccion",
		"headerName": "Sección",
		"headerAlign": "center",
		"align": "center",
		"flex": 2
	},
	{
		"field": "descripcion",
		"headerName": "Descripción",
		"headerAlign": "center",
		"align": "left",
		"flex": 6
	},
	{
		"field": "fecha",
		"headerName": "Fecha",
		"headerAlign": "center",
		"align": "center",
		"flex": 2
	}
];
