import { useState, useEffect } from "react";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import { CssBaseline, ThemeProvider, Box } from "@mui/material";
import { ColorModeContext, useMode } from "./theme/theme";

import Header from "./components/includes/Header";
import Sidebar from "./components/includes/Sidebar";

import Dashboard from "./modules/dashboard";

import Articulo from "./modules/articles";
import NuevoArticulo from "./modules/articles/addNew";
import EditaArticulo from "./modules/articles/edit";
import DetallesArticulo from "./modules/articles/details";

import Compuestos from "./modules/compounds";
import NuevoCompuesto from "./modules/compounds/addNew";
import EditaCompuesto from "./modules/compounds/edit";
import DetallesCompuesto from "./modules/compounds/details";

import Usuarios from "./modules/users";
import NuevoUsuario from "./modules/users/addNew";
import EditaUsuario from "./modules/users/edit";

import Historial from "./modules/history";
import Catalogos from "./modules/catalogs";
import EditaCatalogo from "./modules/catalogs/edit";

import Projectos from "./modules/projects";
import NuevoProjecto from "./modules/projects/addNew";
import DetallesProjecto from "./modules/projects/details";

import Organizations from "./modules/organizations";

import Calendar from "./modules/calendar/calendar";

import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";

import "bootstrap/dist/css/bootstrap.min.css";

// import Login from "./components/includes/Login"; 
import PasswordRecover from "./components/includes/PasswordRecover";
import ChangePassword from "./components/includes/ChangePassword";

library.add(fas);

function App() {
    const [theme, colorMode] = useMode();
    const [isCollapsed, setIsCollapsed] = useState(false);
    const location = useLocation();

    // const [isLoggedIn, setIsLoggedIn] = useState(() => { 
    //     return localStorage.getItem("isLoggedIn") === "true";
    // });

    // useEffect(() => { // Comentar sincronización de sesión
    //     localStorage.setItem("isLoggedIn", isLoggedIn);
    // }, [isLoggedIn]);

    const isLoggedIn = true; 

    return (
        <ColorModeContext.Provider value={colorMode}>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <Box
                    sx={{
                        backgroundColor: "#F4F6F8", 
                        minHeight: "100vh", 
                    }}
                >
                    <Routes>
                        <Route path="/passrecover" element={<PasswordRecover />} />
                        <Route path="/passrecover/change" element={<ChangePassword />} />

                        {isLoggedIn ? (
                            <Route
                                path="/*"
                                element={
                                    <Box display="flex" width="100%">
                                        <Sidebar
                                            isCollapsed={isCollapsed}
                                            setIsCollapsed={setIsCollapsed}
                                        />
                                        <Box
                                            sx={{
                                                marginLeft: isCollapsed ? "80px" : "280px",
                                                padding: "30px",
                                                width: "100%",
                                                transition: "margin-left 0.3s ease-in-out",
                                                marginTop: "30px",
                                            }}
                                        >
                                            <Header
                                                isCollapsed={isCollapsed}
                                                // setIsLoggedIn={setIsLoggedIn} 
                                            />
                                            <Routes>
                                                <Route path="/" element={<Dashboard />} />
                                                <Route path="/articles" element={<Articulo />} />
                                                <Route path="/articles/addNew" element={<NuevoArticulo />} />
                                                <Route path="/articles/edit/:id" element={<EditaArticulo />} />
                                                <Route path="/articles/details/:id" element={<DetallesArticulo />} />

                                                <Route path="/compounds" element={<Compuestos />} />
                                                <Route path="/compounds/addNew" element={<NuevoCompuesto />} />
                                                <Route path="/compounds/edit/:id" element={<EditaCompuesto />} />
                                                <Route path="/compounds/details/:id" element={<DetallesCompuesto />} />

                                                <Route path="/projects" element={<Projectos />} />
                                                <Route path="/projects/addNew" element={<NuevoProjecto />} />
                                                <Route path="/projects/details/:id" element={<DetallesProjecto />} />

                                                <Route path="/users" element={<Usuarios />} />
                                                <Route path="/users/addNew/" element={<NuevoUsuario />} />
                                                <Route path="/users/edit/:id" element={<EditaUsuario />} />

                                                <Route path="/catalogs" element={<Catalogos />} />
                                                <Route path="/catalogs/edit/:id" element={<EditaCatalogo />} />

                                                <Route path="/organizations" element={<Organizations />} />

                                                <Route path="/historial" element={<Historial />} />
                                                <Route path="/calendar" element={<Calendar />} />
                                            </Routes>
                                        </Box>
                                    </Box>
                                }
                            />
                        ) : (
                            // <Route
                            //     path="/*"
                            //     element={<Login onLogin={() => setIsLoggedIn(true)} />}
                            // />
                            <Navigate to="/" />
                        )}

                        <Route path="*" element={<Navigate to="/" />} />
                    </Routes>
                </Box>
            </ThemeProvider>
        </ColorModeContext.Provider>
    );
}

export default App;
