


export const projectList = [
	{
		"field": "nombre",
		"headerName": "Nombre del Proyecto",
		"headerAlign": "center",
		"align": "center",
		"flex": 1
	},
	{
		"field": "descripcion",
		"headerName": "Descripción",
		"headerAlign": "center",
		"align": "center",
		"flex": 1
	},
	{
		"field": "total",
		"headerName": "Costo Total",
		"flex": 1,
		"headerAlign": "center",
		"align": "center",
		"flex": 1
	},
	{
		"field": "status",
		"headerName": "Estado",
		"headerAlign": "center",
		"align": "center",
		"currency": 1,
		"width": 100
	},
	{
		"field": "acciones",
		"headerName": "Acciones",
		"flex": 1,
		"headerAlign": "center",
		"align": "center",
		"flex": 1,
		"botons": {
			"editar": {
				"label": "Ver Proyecto",
				"class": "info",
				"type": "link",
				"action": "details/${row.id}"
			}
		}
	}
];

export const proyectDetails = [
	{
		"field": "clave",
		"headerName": "Categoría / Clave Artículo",
		"headerAlign": "center",
		"align": "center",
		"width": 400
	},
	{
		"field": "acciones",
		"headerName": "Acciones",
		"flex": 1,
		"headerAlign": "center",
		"align": "center",
		"width": 100
	},
	{
		"field": "tipo",
		"headerName": "Tipo",
		"headerAlign": "center",
		"detalle": "Descripcion del Tipo",
		"align": "center",
		"width": 100
	},
	{
		"field": "descCorta",
		"headerName": "Descripción",
		"headerAlign": "center",
		"detalle": "Descripcion del Tipo",
		"align": "center",
		"width": 100
	},
	{
		"field": "familia",
		"headerName": "Familia",
		"headerAlign": "center",
		"detalle": "Descripcion del Tipo",
		"align": "center",
		"width": 100
	},
	{
		"field": "unidad",
		"headerName": "Unidad",
		"headerAlign": "center",
		"detalle": "Descripcion del Tipo",
		"align": "center",
		"width": 100
	},
	{
		"field": "precioVenta",
		"headerName": "Precio de Venta",
		"headerAlign": "center",
		"align": "center",
		"currency": 1,
		"width": 100
	},
	{
		"field": "status",
		"headerName": "Estado",
		"headerAlign": "center",
		"align": "center",
		"currency": 1,
		"width": 100
	}
];

export const tableData = [
	{
		nombre: 'Categoría 1',
		tipo: 'Categoría',
		icono: '',
		descripcion: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor...',
		familia: 'Familia A',
		unidad: 'Unidad A',
		precio: '$100',
		status: 'Activado',
		cat: [
			{
				nombre: 'Artículo Compuesto 1',
				tipo: 'Compuesto',
				icono: '',
				descripcion: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor...',
				familia: 'Familia C',
				unidad: 'Unidad C',
				precio: '$25',
				status: 'Activado',
				art: [
					{
						nombre: 'Registro 2.1',
						tipo: 'Simple',
						icono: '',
						descripcion: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor...',
						familia: 'Familia B',
						unidad: 'Unidad B',
						precio: '$50',
						status: 'Activado'
					},
				],
			},
			{
				nombre: 'Artículo Simple 2',
				tipo: 'Simple',
				icono: '',
				descripcion: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor...',
				familia: 'Familia D',
				unidad: 'Unidad D',
				precio: '$30',
				status: 'Activado'
			},
		],
		art: [
			{
				nombre: 'Categoría 1.1',
				tipo: 'Categoría',
				icono: '',
				descripcion: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor...',
				familia: 'Familia B',
				unidad: 'Unidad B',
				precio: '$50',
				status: 'Activado',
				art: [
					{
						nombre: 'Registro 1.1.1',
						tipo: 'Compuesto',
						icono: '',
						descripcion: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor...',
						familia: 'Familia C',
						unidad: 'Unidad C',
						precio: '$25',
						status: 'Activado',
						art: [
							{
								nombre: 'Registro 1.1.1',
								tipo: 'Compuesto',
								icono: '',
								descripcion: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor...',
								familia: 'Familia C',
								unidad: 'Unidad C',
								precio: '$25',
								status: 'Activado',
								art: [
									{
										nombre: 'Registro 1.1.1',
										tipo: 'Compuesto',
										icono: '',
										descripcion: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor...',
										familia: 'Familia C',
										unidad: 'Unidad C',
										precio: '$25',
										status: 'Activado',
										art: [
											{
												nombre: 'Registro 1.1.1',
												tipo: 'Compuesto',
												icono: '',
												descripcion: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor...',
												familia: 'Familia C',
												unidad: 'Unidad C',
												precio: '$25',
												status: 'Activado',
												art: [
													{
														nombre: 'Registro 1.1.1',
														tipo: 'Simple',
														icono: '',
														descripcion: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor...',
														familia: 'Familia C',
														unidad: 'Unidad C',
														precio: '$25',
														status: 'Activado'
													},
													{
														nombre: 'Registro 1.1.2',
														tipo: 'Compuesto',
														icono: '',
														descripcion: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor...',
														familia: 'Familia D',
														unidad: 'Unidad D',
														precio: '$30',
														status: 'Activado',
														art: [
															{
																nombre: 'Registro 1.1.1',
																tipo: 'Compuesto',
																icono: '',
																descripcion: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor...',
																familia: 'Familia C',
																unidad: 'Unidad C',
																precio: '$25',
																status: 'Activado',
																art: [
																	{
																		nombre: 'Registro 1.1.1',
																		tipo: 'Simple',
																		icono: '',
																		descripcion: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor...',
																		familia: 'Familia C',
																		unidad: 'Unidad C',
																		precio: '$25',
																		status: 'Activado'
																	},
																	{
																		nombre: 'Registro 1.1.2',
																		tipo: 'Simple',
																		icono: '',
																		descripcion: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor...',
																		familia: 'Familia D',
																		unidad: 'Unidad D',
																		precio: '$30',
																		status: 'Activado'
																	},
																],
															},
															{
																nombre: 'Registro 1.1.2',
																tipo: 'Simple',
																icono: '',
																descripcion: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor...',
																familia: 'Familia D',
																unidad: 'Unidad D',
																precio: '$30',
																status: 'Activado'
															},
														],
													},
												],
											},
											{
												nombre: 'Registro 1.1.2',
												tipo: 'Compuesto',
												icono: '',
												descripcion: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor...',
												familia: 'Familia D',
												unidad: 'Unidad D',
												precio: '$30',
												status: 'Activado',
												art: [
													{
														nombre: 'Registro 1.1.1',
														tipo: 'Compuesto',
														icono: '',
														descripcion: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor...',
														familia: 'Familia C',
														unidad: 'Unidad C',
														precio: '$25',
														status: 'Activado',
														art: [
															{
																nombre: 'Registro 1.1.1',
																tipo: 'Simple',
																icono: '',
																descripcion: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor...',
																familia: 'Familia C',
																unidad: 'Unidad C',
																precio: '$25',
																status: 'Activado'
															},
															{
																nombre: 'Registro 1.1.2',
																tipo: 'Simple',
																icono: '',
																descripcion: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor...',
																familia: 'Familia D',
																unidad: 'Unidad D',
																precio: '$30',
																status: 'Activado'
															},
														],
													},
													{
														nombre: 'Registro 1.1.2',
														tipo: 'Simple',
														icono: '',
														descripcion: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor...',
														familia: 'Familia D',
														unidad: 'Unidad D',
														precio: '$30',
														status: 'Activado'
													},
												],
											},
										],
									},
									{
										nombre: 'Registro 1.1.2',
										tipo: 'Simple',
										icono: '',
										descripcion: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor...',
										familia: 'Familia D',
										unidad: 'Unidad D',
										precio: '$30',
										status: 'Activado'
									},
								],
							},
							{
								nombre: 'Registro 1.1.2',
								tipo: 'Simple',
								icono: '',
								descripcion: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor...',
								familia: 'Familia D',
								unidad: 'Unidad D',
								precio: '$30',
								status: 'Activado'
							},
						],
					},
					{
						nombre: 'Registro 1.1.2',
						tipo: 'Simple',
						icono: '',
						descripcion: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor...',
						familia: 'Familia D',
						unidad: 'Unidad D',
						precio: '$30',
						status: 'Activado'
					},
				],
			},
		],
	},
	{
		nombre: 'Categoría 2',
		tipo: 'Categoría',
		icono: '',
		descripcion: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor...',
		familia: 'Familia C',
		unidad: 'Unidad C',
		precio: '$200',
		status: 'Activado',
		art: [
			{
				nombre: 'Registro 2.1',
				tipo: 'Simple',
				icono: '',
				descripcion: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor...',
				familia: 'Familia B',
				unidad: 'Unidad B',
				precio: '$50',
				status: 'Activado'
			},
		],
	},
]