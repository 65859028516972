import axios from 'axios';

export async function getPostData(url, params) {
	try {
		const response = await axios.post(url, params, {
			headers: {
				'Content-Type': 'application/json',
			},
		});
		const jsonData = Array.isArray(response) ? response : response.data;
		return jsonData;
	} catch (error) {
		console.error('Error fetching data:', error);
		throw error;
	}
}

export async function sendPostData(url, params) {
	try {
		const response = await axios.post(url, params, {
			headers: {
				'Content-Type': 'application/json',
			},
		});

		console.log('Solicitud POST exitosa:', response.data);
		return response.data;
	} catch (error) {
		console.error('Error al enviar la solicitud POST:', error);
		throw error;
	}
}

export const getDataArray = async (url, payload) => {
    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ ...payload })
        });

        // Agrega un console.log para ver la respuesta completa
        console.log('Response:', response);

        if (!response.ok) {
            // Imprime el texto de la respuesta para obtener más detalles del error
            const errorText = await response.text();
            console.error('Error response text:', errorText);
            throw new Error('Network response was not ok');
        }

        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error fetching data:', error);
        throw error;
    }
};
