import React from "react";
import { SimpleDataTable } from "../../components/globals/newDataTable";
import { tableHeaders } from "../../data/tableHeaders/organizations";

const Organizations = () => {
    const dataConfig = {
        title: "Organizaciones",
        module: "organizations",
        tableHeader: tableHeaders,
        endpoint: "listado/organizaciones",
        catalogo: "organizaciones",
    };

    return (
        <SimpleDataTable data={dataConfig} />
    );
};

export default Organizations;
