import { GeneraFormularioSimple } from "../../components/globals/globalForms";
import { newArticle } from "../../data/forms/articles";
import * as yup from "yup";

const addNewRcd = () => {
	// Parámetros de Configuración para cargar los datos requeridos
	// ----------------------------------------------------------------------------------------
		const initValues = {
			clave: '',
			tipoArtID: '',
			famID: '',
			cuentaID: '',
			unidadID: '',
			descCorta: '',
			descLarga: '',
			imagen: ''
		};

		const dataConfig = {
			dbID: "id",
			title: "Nuevo Registro de Articulo",
			key: newArticle,
			module: 'articles',
			description: "Ingrese los datos necesarios para crear un nuevo registro de Articulo",
			titleBtn: "Nuevo Registro",
			colorBtn: "secondary",
			msgSuccess: "Articulo creado exitosamente",
			msgError: "Error al crear el Registro",
			sendData: "nuevo/articulo"
		}
		
		const validateSchemas = yup.object().shape({
			clave: yup.string().required('Requerido'),
			tipoArtID: yup.number().required('Requerido'),
			famID: yup.number().required('Requerido'),
			cuentaID: yup.number().required('Requerido'),
			unidadID: yup.number().required('Requerido'),
			descCorta: yup.string(),
			descLarga: yup.string(),
			imagen: yup.string().required('Requerido')
		});
	// ----------------------------------------------------------------------------------------

	// No Tocar este bloque de código
	// ========================================================================================
		return (
			<GeneraFormularioSimple 
				data={dataConfig} 
				initValues={initValues} 
				validateSchemas={validateSchemas} 
			/>
		)
	// ========================================================================================
}

export default addNewRcd;
