export const tableHeaders = [
	{
	  field: "usuarioDetalle",
	  headerName: "Usuario",
	  headerAlign: "center",
	  align: "center",
	  flex: 2,
	},
	{
	  field: "correo",
	  headerName: "Correo Electrónico",
	  headerAlign: "center",
	  align: "center",
	  flex: 2,
	},
	{
	  field: "tipoUsuID",
	  headerName: "Perfil",
	  headerAlign: "center",
	  align: "center",
	  flex: 1,
	},
	{
	  field: "status",
	  headerName: "Activo",
	  headerAlign: "center",
	  align: "center",
	  flex: 1,
	  isSwitch: true, 
	},
	{
	  field: "acciones",
	  headerName: "",
	  headerAlign: "center",
	  align: "center",
	  flex: 1,
	  botons: {
		eliminar: {
		  label: "Eliminar",
		  class: "error",
		  type: "link",
		  action: "delete/${row.id}",
		},
		editar: {
			label: "Editar",
			class: "primary",
			type: "link",
			action: "edit/${row.id}",
		  },
	  },
	},
  ];
  