import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import { Box, TextField, Container, Grid, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, useTheme, CircularProgress } from '@mui/material';

import { Formik } from "formik";
import { useSnackbar } from 'notistack';
import { tokens } from "../../data/tokens";
import { useNavigate } from 'react-router-dom';
import { sendPostData } from '../../system/getData';
import useMediaQuery from "@mui/material/useMediaQuery";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faLock, faPaperPlane } from '@fortawesome/free-solid-svg-icons';

import { GetCompoundAmounts } from "../compounds/compoundList";
import ComboBoxFilled from "../globals/comboBoxFilled";

import { UseAppConfig } from '../../system/config';
import { Header } from "../basics/Header";


export const GeneraFormularioSimple = ({ data, initValues, validateSchemas }) => {
	const navigate = useNavigate();
	const { enqueueSnackbar } = useSnackbar();
	const isNonMobile = useMediaQuery("(min-width:600px)");

	const { config, configData } = UseAppConfig();

	const formatCurrency = (value) => {
		return new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(value);
	};

	const handleFormSubmit = async (values) => {
		console.log('Detalles del Registro: ' + JSON.stringify(values));
		console.log('Enviando datos a la API');
		try {
			const result = await sendPostData(`${config.API_BASE_URL}/${data.sendData}`, values);
			console.log('Formulario actualizado exitosamente:', result);
			enqueueSnackbar(data.msgSuccess, { variant: 'success' });
			navigate(`/${data.module}`);
		} catch (error) {
			console.error('Error al enviar datos:', error);
			enqueueSnackbar(data.msgError, { variant: 'error' });
		}
	};

	const handleBack = () => {
		navigate(-1);
	};

	return (
		<Box
			sx={{
				width: "90%",
				backgroundColor: "#f0f0f0",
				margin: "0 auto",
				padding: "20px",
				borderRadius: "10px",
			}}
		>
			<Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
				<Button variant="contained" onClick={handleBack} color="success" autoFocus>
					Regresar
				</Button>
			</Box>

			<Header title={data.title} subtitle={data.description} />
			<Formik
				onSubmit={handleFormSubmit}
				initialValues={initValues}
			// validationSchema={validateSchemas}
			>
				{({
					values,
					errors,
					touched,
					handleBlur,
					handleChange,
					handleSubmit,
					setFieldValue,
				}) => (
					<form onSubmit={(e) => {
						console.log('Formulario intentando enviarse');
						handleSubmit(e);
					}}>
						<Box
							sx={{
								width: "85%",
								margin: "0 auto",
								borderRadius: "15px",
								display: "grid",
								gap: "10px",
								gridTemplateColumns: "repeat(4, minmax(0, 1fr))",
							}}
						>
							{data.key.map((field, index) => (
								field.catalog?.length > 0 ? (
									<ComboBoxFilled
										key={index}
										data={field}
										index={index}
										value={values[field.name]}
										onChange={(event) => setFieldValue(field.name, event.target.value)}
									/>
								) : (
									<div key={index}>
										<b style={{ marginTop: '20px', display: 'block' }}>{field.label}</b>
										<TextField
											key={index}
											fullWidth
											variant="filled"
											type="text"
											onBlur={handleBlur}
											onChange={handleChange}
											value={values[field.name]}
											name={field.name}
											disabled={field.disabled}
											error={!!touched[field.name] && !!errors[field.name]}
											helperText={touched[field.name] && errors[field.name]}
											sx={{ gridColumn: field.gridColumn }}
										/>
									</div>
								)
							))}
						</Box>
						<Box display="flex" justifyContent="end" mt="20px">
							<Button type="submit" color={data.colorBtn} variant="contained">
								{data.titleBtn}
							</Button>
						</Box>
					</form>
				)}
			</Formik>
		</Box>
	);
};

export const FormatoArticulosCompuestos = ({ data, initValues, validateSchemas }) => {
	const [setRows] = useState([]);

	const navigate = useNavigate();
	const { enqueueSnackbar } = useSnackbar();

	const theme = useTheme();
	const colors = tokens(theme.palette.mode);

	const currentDate = new Date();

	const { config, configData } = UseAppConfig();

	const [values, setValues] = useState({});
	const [isButtonDisabled, setIsButtonDisabled] = useState(true);

	const formattedDate = currentDate.toLocaleDateString('es-ES', {
		weekday: 'long',
		year: 'numeric',
		month: 'long',
		day: 'numeric'
	});

	const formatCurrency = (value) => {
		return new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(value);
	};

	const handleFormSubmit = async (values) => {
		console.log('Nuevo Precio de Compra: ' + JSON.stringify(values));
		try {
			const result = await sendPostData(`${config.API_BASE_URL}${data.sendData}`, values);
			console.log('Formulario actualizado exitosamente:', result);
			enqueueSnackbar(data.msgSuccess, { variant: 'success' });
			navigate(`/${data.module}`);
		} catch (error) {
			enqueueSnackbar(data.msgError, { variant: 'error' });
		}
	};

	const handleSaleSubmit = async (values) => {
		console.log('Nuevo Precio de Venta: ' + JSON.stringify(values));
		try {
			const result = await sendPostData(`${config.API_BASE_URL}${data.sendData2}`, values);
			console.log('Formulario actualizado exitosamente:', result);
			enqueueSnackbar('Precio de Venta actualizado exitosamente', { variant: 'success' });
			navigate(`/${data.module}`);
		} catch (error) {
			enqueueSnackbar(data.msgError, { variant: 'error' });
		}
	};

	const handleChange = (event) => {
		const { name, value } = event.target;
		setValues({
			...values,
			[name]: value,
		});

		if (value !== '') {
			setIsButtonDisabled(false);
		} else {
			setIsButtonDisabled(true);
		}
	};

	const handleBack = () => {
		navigate(-1);
	};

	return (
		<Container maxWidth="x2">
			<Header title={data.title} subtitle={data.description} />
			<Grid container justifyContent="center" className="row-printable">
				<Grid item xs={12} md={12}>
					<Paper elevation={3} className="panel">
						<Box p={3} className="panel-body">
							<Grid item xs={12} sm={6}>
								<Box className="invoice-from" textAlign="right">
									{(() => {
										switch (initValues.tipo) {
											case 'Simple':
												return (
													<Formik
														initialValues={initValues}
														validationSchema={validateSchemas}
														onSubmit={handleFormSubmit}
													>
														{({
															values,
															errors,
															touched,
															handleBlur,
															handleChange,
															handleSubmit,
															isValid,
															dirty
														}) => (
															<div style={{ position: 'absolute', top: '130px', right: '50px' }}>
																<Box display="flex" justifyContent="space-between">
																	{/* Primer Formulario para Precio de Compra */}
																	<Box>
																		<form onSubmit={handleSubmit}>
																			<Box>
																				<div style={{ marginBottom: '-24px' }}>
																					<span>Precio de Compra</span><br />
																					<span style={{ position: 'absolute', marginTop: '25px', fontSize: '40px', color: 'green', textAlign: 'center' }}>$</span>
																					<TextField
																						variant="filled"
																						type="number"
																						className="input-number"
																						onBlur={handleBlur}
																						onChange={handleChange}
																						value={values['precioCompra']}  // Se usa 'precioCompra'
																						name="precioCompra"
																						sx={{
																							width: '135px',
																							'& .MuiInputBase-input': {
																								fontSize: '40px',
																								color: 'green',
																								textAlign: 'center'
																							},
																						}}
																					/>
																				</div>
																			</Box>
																			<Box display="flex" justifyContent="end" mt="20px">
																				<Button
																					type="submit"
																					color={data.colorBtn}
																					variant="contained"
																					disabled={!dirty || !isValid}
																				>
																					{data.titleBtn}
																				</Button>
																			</Box>
																		</form>
																	</Box>

																	{/* Segundo Formulario para Precio de Venta */}
																	<Formik
																		initialValues={initValues}
																		validationSchema={validateSchemas}
																		onSubmit={handleSaleSubmit}
																	>
																		{({
																			values: saleValues,
																			handleChange: handleSaleChange,
																			handleSubmit: handleSaleFormSubmit,
																			isValid: isSaleValid,
																			dirty: isSaleDirty
																		}) => (
																			<Box style={{ marginLeft: '20px', marginTop: '0px' }}>
																				<form onSubmit={handleSaleFormSubmit}>
																					<Box>
																						<div style={{ marginBottom: '-24px' }}>
																							<span>Precio de Venta</span><br />
																							<span style={{ position: 'absolute', marginTop: '25px', fontSize: '40px', color: 'blue', textAlign: 'center' }}>$</span>
																							<TextField
																								variant="filled"
																								type="number"
																								className="input-number"
																								onChange={handleSaleChange}
																								value={saleValues['precioVenta']}  // Se usa 'precioVenta'
																								name="precioVenta"
																								sx={{
																									width: '120px',
																									'& .MuiInputBase-input': {
																										fontSize: '40px',
																										color: 'blue',
																										textAlign: 'center'
																									},
																								}}
																							/>
																						</div>
																					</Box>
																					<Box display="flex" justifyContent="end" mt="20px">
																						<Button
																							type="submit"
																							color={data.colorBtn2}
																							variant="contained"
																							disabled={!isSaleDirty || !isSaleValid}
																						>
																							{data.titleBtn2}
																						</Button>
																					</Box>
																				</form>
																			</Box>
																		)}
																	</Formik>
																</Box>
															</div>
														)}
													</Formik>
												);
											default:
												return null;
										}
									})()}
								</Box>
							</Grid>
							<Grid item xs={12}>
								<Box className="invoice-details" mt={3}>
									<Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
										<Button variant="contained" onClick={handleBack} color="success" autoFocus>
											Regresar
										</Button>
									</Box>
									<Box className="invoice-to" mt={3}>
										<Paper className="well">
											<ul className="list-unstyled mb0">
												<li><strong>Nombre:</strong> {initValues.descCorta}</li>
												<li><strong>Clave</strong> {initValues.clave}</li>
												<li><strong>Tipo:</strong> {initValues.tipo}</li>
												<li><strong>Familia:</strong> {initValues.familia}</li>
												<li><strong>Cuenta:</strong> {initValues.cuenta}</li>
												<li><strong>Tipo de Unidad:</strong> por {initValues.unidad}</li>
												<br /><br />
												<li style={{ marginBottom: '35px' }}><strong>Descripción del Servicio:</strong><br />
													{initValues.descLarga}</li>
											</ul>
										</Paper>
									</Box>
								</Box>
								{(() => {
									switch (initValues.tipo) {
										case 'Simple':
											return (
												<Box className="invoice-items">
													<TableContainer>
														<Table>
															<TableHead>
																<TableRow style={{ backgroundColor: colors.grey[900] }}>
																	<TableCell className="per70 text-left"><b>Descripción</b></TableCell>
																	<TableCell className="per5 text-center"><b>Estado</b></TableCell>
																	<TableCell className="per25 text-center"><b>Precio de Compra actual</b></TableCell>
																	<TableCell className="per5 text-center"><b>Acciones</b></TableCell>
																</TableRow>
															</TableHead>
															<TableBody>
																<TableRow>
																	<TableCell>{initValues.descLarga}</TableCell>
																	<TableCell className="text-center">{initValues.status}</TableCell>
																	<TableCell className="text-center">{formatCurrency(initValues.precioCompra)}</TableCell>
																	<TableCell className="text-center">
																		<Link key="Editar" to={`../${data.module}/edit/${initValues.id}`} style={{ marginRight: '5px' }}>
																			<Button type="submit" color="warning" variant="contained">
																				Editar
																			</Button>
																		</Link>
																	</TableCell>
																</TableRow>
															</TableBody>
														</Table>
													</TableContainer>
												</Box>
											);
										case 'Compuesto':
											return (
												<Box className="invoice-footer" mt={3} textAlign="center">
													<GetCompoundAmounts
														data={data}
														initValues={initValues}
													/>
												</Box>
											);
										default:
											return null;
									}
								})()}
								<Box className="invoice-footer" mt={3} textAlign="center">
									<Typography>Generado el {formattedDate}</Typography>
								</Box>
							</Grid>
						</Box>
					</Paper >
				</Grid >
			</Grid >
		</Container >
	);
};

export const GeneraFormularioAutenticacion = ({data, initValues, validateSchemas, handleFormSubmit }) => {
	const [rememberUser, setRememberUser] = useState(false);
	const [formValues, setFormValues] = useState(initValues);
	const [passwordVisible, setPasswordVisible] = useState(false); 

	useEffect(() => {
		const savedValues = JSON.parse(localStorage.getItem("userCredentials"));
		if (savedValues) {
			setFormValues((prev) => ({ ...prev, ...savedValues }));
			setRememberUser(true);
		}
	}, []);

	const handleCheckboxChange = (event) => {
		const isChecked = event.target.checked;
		setRememberUser(isChecked);
		if (!isChecked) {
			localStorage.removeItem("userCredentials");
		}
	};

	const handleFormSubmitWithRemember = async (values, actions) => {
		try {
			if (rememberUser) {
				localStorage.setItem("userCredentials", JSON.stringify(values));
			}
			await handleFormSubmit(values, actions);
		} catch (error) {
			console.error("Error al autenticar:", error);
		} finally {
			actions.setSubmitting(false);
		}
	};

	const togglePasswordVisibility = () => {
		setPasswordVisible((prev) => !prev); 
	};

	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
				justifyContent: "center",
				minHeight: "100vh",
				position: "relative",
			}}
		>
			<Box
				sx={{
					display: "flex",
					justifyContent: "space-between",
					width: "900px",
				}}
			>
				<Box
					sx={{
						maxWidth: "500px",
						alignItems: "center",
					}}
				>
					<img
						src={`${process.env.PUBLIC_URL}/assets/icons/login/login-banner.svg`}
						alt="PU Logo"
						style={{
							height: "650px",
						}}
					/>
				</Box>
				<Box
					sx={{
						width: "35%",
						maxWidth: "450px",
						paddingLeft: "30px",
						borderRadius: "8px",
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
					}}
				>
					<Typography
						variant="h3"
						gutterBottom
						sx={{
							fontWeight: "bold",
							textAlign: "left",
							marginBottom: "20px",
						}}
					>
						{data.title}
					</Typography>
					<Formik
						initialValues={formValues}
						enableReinitialize
						validationSchema={validateSchemas}
						onSubmit={handleFormSubmitWithRemember}
					>
						{({
							values,
							touched,
							errors,
							handleChange,
							handleBlur,
							handleSubmit,
							isSubmitting,
						}) => (
							<form onSubmit={handleSubmit}>
								{data.fields.map((field) => (
									<Box key={field.name} sx={{ marginBottom: "20px" }}>
										<Typography
											variant="body1"
											sx={{
												textAlign: "left",
												marginBottom: "5px", 
											}}
										>
											{field.label}
										</Typography>
										<TextField
											fullWidth
											variant="standard"
											type={
												field.type === "password" && passwordVisible
													? "text"
													: field.type
											}
											name={field.name}
											value={values[field.name]}
											onChange={handleChange}
											onBlur={handleBlur}
											error={
												touched[field.name] &&
												Boolean(errors[field.name])
											}
											helperText={
												touched[field.name] && errors[field.name]
											}
											placeholder={
												field.name === "correo"
													? "Ingresa un correo electrónico"
													: "Ingresa tu contraseña"
											}
											InputProps={{
												endAdornment: field.type === "password" && (
													<Box
														onClick={togglePasswordVisibility}
														sx={{
															cursor: "pointer", 
															display: "flex", 
															alignItems: "center",
														}}
													>
														<img
															src={`${process.env.PUBLIC_URL}/assets/icons/login/${passwordVisible
																	? "eye-active.svg"
																	: "eye-inactive.svg"
																}`}
															alt="Toggle Password Visibility"
															style={{
																width: "20px", 
																height: "20px",
															}}
														/>
													</Box>
												),
											}}
											sx={{
												"& .MuiInputBase-root": {
													marginTop: "3px",
													borderRadius: "8px",
													border: "1px solid #ccc",
													padding: "2px 8px",
												},
												"& input": {
													"-webkit-text-security":
														field.type === "password" &&
															!passwordVisible
															? "disc"
															: "none", 
													appearance: "none", 
													"&::-ms-reveal, &::-ms-clear": {
														display: "none", 
													},
													"&::-webkit-clear-button, &::-webkit-inner-spin-button, &::-webkit-textfield-decoration-container": {
														display: "none", 
													},
												},
												"& .MuiInput-underline:before, & .MuiInput-underline:after": {
													display: "none", 
												},
											}}
										/>
									</Box>
								))}

								{data.showPasswordLink && (
									<Box
										sx={{
											mt: 1,
											display: "flex",
											alignItems: "center",
											justifyContent: "space-between",
										}}
									>
										<Box sx={{ display: "flex", alignItems: "center" }}>
											<input
												type="checkbox"
												id="rememberUser"
												style={{ marginRight: "5px" }}
												checked={rememberUser}
												onChange={handleCheckboxChange}
											/>
											<label
												htmlFor="rememberUser"
												style={{ fontSize: "12px", color: "#000" }}
											>
												Recordar usuario
											</label>
										</Box>

										<Box>
											<Typography variant="body2" sx={{ textAlign: "right" }}>
												<Link
													to="/passrecover"
													style={{
														color: "#3f51b5",
														textDecoration: "underline",
														fontSize: "12px",
													}}
												>
													Olvidé mi contraseña
												</Link>
											</Typography>
										</Box>
									</Box>
								)}

								<Button
									type="submit"
									variant="contained"
									color="primary"
									fullWidth
									sx={{
										mt: 1,
										height: "36px",
										backgroundColor: "#1D74D3",
										borderRadius: "8px",
										marginTop: "20px",
									}}
									disabled={isSubmitting}
								>
									{isSubmitting ? <CircularProgress size={20} /> : ""}
									{data.titleBtn}
								</Button>
							</form>
						)}
					</Formik>
				</Box>
			</Box>
			<Box
				sx={{
					position: "absolute",
					bottom: 20,
					right: 30,
					textAlign: "right",
					backgroundColor: "transparent",
				}}
			>
				<Typography variant="body2" sx={{ color: "#000" }}>
					2024 @ Exagono Software
				</Typography>
			</Box>
		</Box>
	);
};

export const GeneraFormularioCorreo = ({ data, initValues, validateSchemas, handleFormSubmit }) => {
	const navigate = useNavigate();
	const [isFormVisible, setIsFormVisible] = useState(true);
	const [submittedEmail, setSubmittedEmail] = useState("");

	return (
		<Box
			sx={{
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
				minHeight: "100vh",
				position: "relative", 
			}}
		>
			<Box
				sx={{
					position: "absolute", 
					top: 20,
					left: 20, 
				}}
			>
				<img
					src={`${process.env.PUBLIC_URL}/assets/icons/navigation/PU-logo.svg`}
					alt="PU Logo"
					style={{
						height: "25px",
						pointerEvents: "none",
					}}
				/>
			</Box>

			<Box
				sx={{
					display: "flex",
					alignItems: "center",
					justifyContent: "space-between",
					width: "900px",
				}}
			>
				<Box sx={{ width: "30%" }}>
					{isFormVisible ? (
						<>
							<Typography
								variant="h4"
								gutterBottom
								sx={{
									fontWeight: "bold",
									textAlign: "left",
									marginBottom: "20px",
								}}
							>
								{data.title}
							</Typography>
							<Typography
								variant="body1"
								sx={{
									textAlign: "left",
									color: "#666",
									marginBottom: "20px",
								}}
							>
								{data.description}
							</Typography>
							<Formik
								initialValues={initValues}
								validationSchema={validateSchemas}
								onSubmit={(values, { setSubmitting }) => {
									handleFormSubmit(values);
									setSubmittedEmail(values.correo);
									setSubmitting(false);
									setIsFormVisible(false);
								}}
							>
								{({
									values,
									touched,
									errors,
									handleChange,
									handleBlur,
									handleSubmit,
									isSubmitting,
								}) => (
									<form onSubmit={handleSubmit}>
										<Box>
											<Typography
												variant="body1"
												sx={{
													fontWeight: "bold",
													textAlign: "left",
													marginBottom: "10px",
												}}
											>
												Correo electrónico
											</Typography>
											<TextField
												fullWidth
												variant="standard"
												type="email"
												name="correo"
												value={values.correo}
												onChange={handleChange}
												onBlur={handleBlur}
												error={touched.correo && Boolean(errors.correo)}
												helperText={touched.correo && errors.correo}
												placeholder="Ingresa tu correo electrónico"
												sx={{
													"& .MuiInputBase-root": {
														marginTop: "3px",
														borderRadius: "8px",
														border: "1px solid #ccc",
														padding: "2px 8px",
													},
													"& .MuiInput-underline:before, & .MuiInput-underline:after": {
														display: "none",
													},
												}}
											/>
										</Box>
										<Box
											sx={{
												display: "flex",
												justifyContent: "space-between",
												gap: "16px",
												marginTop: "30px",
											}}
										>
											<Button
												variant="contained"
												sx={{
													height: "40px",
													borderRadius: "5px",
													width: "48%",
													backgroundColor: "#374151",
													color: "#fff",
													"&:hover": {
														backgroundColor: "#4b5563",
													},
												}}
												onClick={() => navigate("/login")}
											>
												Cancelar
											</Button>
											<Button
												type="submit"
												variant="contained"
												color="primary"
												sx={{
													height: "40px",
													borderRadius: "5px",
													width: "300px",
													backgroundColor: "#1D74D3",
												}}
												disabled={isSubmitting}
											>
												{isSubmitting ? (
													<CircularProgress size={20} />
												) : (
													data.titleBtn
												)}
											</Button>
										</Box>
									</form>
								)}
							</Formik>
						</>
					) : (
						<>
							<Typography
								variant="h3"
								gutterBottom
								sx={{
									fontWeight: "bold",
									textAlign: "left",
									marginBottom: "20px",
								}}
							>
								Revisa tu e-mail
							</Typography>
							<Typography
								variant="body1"
								sx={{
									textAlign: "left",
									color: "#666",
									marginBottom: "10px",
								}}
							>
								Hemos enviado un correo electrónico con instrucciones para recuperar tu contraseña.
							</Typography>
							<Box
								sx={{
									display: "flex",
									alignItems: "center",
									gap: "8px",
									marginBottom: "30px",
								}}
							>
								<Typography
									variant="body1"
									sx={{
										color: "#1D74D3",
										cursor: "pointer",
										display: "flex",
										alignItems: "center",
									}}
								>
									{submittedEmail}
								</Typography>
								<img
									src={`${process.env.PUBLIC_URL}/assets/icons/login/editar-cl.svg`}
									alt="Editar correo"
									style={{
										height: "15px",
										cursor: "pointer",
									}}
									onClick={() => {
										setIsFormVisible(true);
										setSubmittedEmail("");
									}}
								/>
							</Box>
							<Button
								variant="contained"
								color="primary"
								sx={{
									width: "50%",
									height: "40px",
									borderRadius: "5px",
									backgroundColor: "#1D74D3",
								}}
								onClick={() =>
									navigate("/login", { state: { email: submittedEmail } })
								}
							>
								Aceptar
							</Button>
						</>
					)}
				</Box>
				<Box>
					<img
						src={`${process.env.PUBLIC_URL}/assets/icons/login/recovery-pss_img.svg`}
						alt="Banner"
						style={{
							height: "650px",
						}}
					/>
				</Box>
			</Box>
		</Box>
	);
};

export const GeneraFormularioCambioContrasena = ({data, initValues, validateSchemas, handleFormSubmit }) => {
	const navigate = useNavigate();
	const [isFormVisible, setIsFormVisible] = useState(true);
	const [validationStatus, setValidationStatus] = useState({
		hasUppercase: false,
		hasLowercase: false,
		hasNumber: false,
		hasSpecialChar: false,
	});
	const [passwordVisible, setPasswordVisible] = useState(false);

	const handlePasswordChange = (e) => {
		const value = e.target.value;
		setValidationStatus({
			hasUppercase: /[A-Z]/.test(value),
			hasLowercase: /[a-z]/.test(value),
			hasNumber: /\d/.test(value),
			hasSpecialChar: /[@$!%*?&]/.test(value),
		});
	};

	const ValidationIcon = ({ isActive }) => (
		<>
			{isActive ? (
				<img
					src={`${process.env.PUBLIC_URL}/assets/icons/login/check-ic_color.svg`}
					alt="Check Icon"
					style={{
						height: "16px",
						marginRight: "8px",
					}}
				/>
			) : (
				<span
					style={{
						display: "inline-block",
						marginRight: "8px",
						fontSize: "16px",
						color: "#000",
					}}
				>
					•
				</span>
			)}
		</>
	);

	return (
		<Box
			sx={{
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
				minHeight: "100vh",
				backgroundColor: "#EDEDEE",
				padding: "20px",
			}}
		>
			<Box
				sx={{
					width: "100%",
					maxWidth: "350px",
					backgroundColor: "#fff",
					boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.3)",
					borderRadius: "5px",
					padding: "30px",
					textAlign: "center",
				}}
			>
				{isFormVisible ? (
					<>
						<Box sx={{ marginBottom: "20px" }}>
							<img
								src={`${process.env.PUBLIC_URL}/assets/icons/login/cambiar-pss-img.svg`}
								alt="Recover Password Icon"
								style={{ height: "70px", paddingBottom: "10px" }}
							/>
						</Box>
						<Typography
							variant="h4"
							gutterBottom
							sx={{
								fontWeight: "bold",
								textAlign: "left",
								marginBottom: "20px",
							}}
						>
							{data.title}
						</Typography>
						<Formik
							initialValues={initValues}
							validationSchema={validateSchemas}
							onSubmit={(values, { setSubmitting }) => {
								handleFormSubmit(values);
								setSubmitting(false);
								setIsFormVisible(false);
							}}
						>
							{({
								values,
								touched,
								errors,
								handleChange,
								handleBlur,
								handleSubmit,
								isSubmitting,
							}) => (
								<form onSubmit={handleSubmit}>
									<Box sx={{ position: "relative", marginBottom: "20px" }}>
										<Typography
											variant="body1"
											sx={{
												textAlign: "left",
												marginBottom: "5px",
											}}
										>
											{data.fields[0].label}
										</Typography>
										<TextField
											fullWidth
											variant="standard"
											type={passwordVisible ? "text" : "password"}
											name={data.fields[0].name}
											value={values[data.fields[0].name]}
											onChange={(e) => {
												handleChange(e);
												handlePasswordChange(e);
											}}
											onBlur={handleBlur}
											error={
												touched[data.fields[0].name] &&
												Boolean(errors[data.fields[0].name])
											}
											helperText={
												touched[data.fields[0].name] &&
												errors[data.fields[0].name]
											}
											sx={{
												"& .MuiInputBase-root": {
													marginTop: "3px",
													borderRadius: "8px",
													border: "1px solid #ccc",
													padding: "2px 8px",

												},
												"& input": {
													"-webkit-text-security": {
														appearance: "none"
													},
													"&::-ms-reveal, &::-ms-clear": {
														display: "none", 
													},
													"&::-webkit-clear-button, &::-webkit-inner-spin-button, &::-webkit-textfield-decoration-container": {
														display: "none", 
													},
												},
												"& .MuiInput-underline:before, & .MuiInput-underline:after": {
													display: "none",
												},
											}}
										/>
									</Box>
									<Box
										sx={{
											textAlign: "left",
											fontSize: "12px",
											marginBottom: "20px",
										}}
									>
										<Typography
											variant="body1"
											sx={{
												marginBottom: "10px",
											}}
										>
											La contraseña debe incluir al menos:
										</Typography>
										<ul
											style={{
												fontSize: "14px",
												paddingLeft: "20px",
												listStyle: "none",
											}}
										>
											<li
												style={{
													display: "flex",
													alignItems: "center",
													color: validationStatus.hasUppercase
														? "#10B981"
														: "#000",
												}}
											>
												<ValidationIcon
													isActive={validationStatus.hasUppercase}
												/>
												Una letra mayúscula
											</li>
											<li
												style={{
													display: "flex",
													alignItems: "center",
													color: validationStatus.hasLowercase
														? "#10B981"
														: "#000",
												}}
											>
												<ValidationIcon
													isActive={validationStatus.hasLowercase}
												/>
												Una minúscula
											</li>
											<li
												style={{
													display: "flex",
													alignItems: "center",
													color: validationStatus.hasNumber
														? "#10B981"
														: "#000",
												}}
											>
												<ValidationIcon
													isActive={validationStatus.hasNumber}
												/>
												Un número
											</li>
											<li
												style={{
													display: "flex",
													alignItems: "center",
													color: validationStatus.hasSpecialChar
														? "#10B981"
														: "#000",
												}}
											>
												<ValidationIcon
													isActive={validationStatus.hasSpecialChar}
												/>
												Un carácter especial
											</li>
										</ul>
									</Box>
									<Box sx={{ position: "relative", marginBottom: "20px" }}>
										<Typography
											variant="body1"
											sx={{
												textAlign: "left",
												marginBottom: "5px",
											}}
										>
											{data.fields[1].label}
										</Typography>
										<TextField
											fullWidth
											variant="standard"
											type={passwordVisible ? "text" : "password"}
											name={data.fields[1].name}
											value={values[data.fields[1].name]}
											onChange={(e) => {
												handleChange(e);
												handlePasswordChange(e);
											}}
											onBlur={handleBlur}
											error={
												touched[data.fields[1].name] &&
												Boolean(errors[data.fields[1].name])
											}
											helperText={
												touched[data.fields[1].name] &&
												errors[data.fields[1].name]
											}
											sx={{
												"& .MuiInputBase-root": {
													marginTop: "3px",
													borderRadius: "8px",
													border: "1px solid #ccc",
													padding: "2px 8px",

												},
												"& input": {
													"-webkit-text-security": {
														appearance: "none"
													},
													"&::-ms-reveal, &::-ms-clear": {
														display: "none", 
													},
													"&::-webkit-clear-button, &::-webkit-inner-spin-button, &::-webkit-textfield-decoration-container": {
														display: "none", 
													},
												},
												"& .MuiInput-underline:before, & .MuiInput-underline:after": {
													display: "none",
												},
											}}
										/>
									</Box>

									<Button
										type="submit"
										variant="contained"
										color="primary"
										sx={{
											height: "45px",
											borderRadius: "5px",
											width: "100%",
											backgroundColor: "#1D74D3",
											fontWeight: "bold",
											"&:hover": {
												backgroundColor: "#155A9C",
											},
										}}
										disabled={isSubmitting}
									>
										{isSubmitting ? (
											<CircularProgress size={20} />
										) : (
											data.titleBtn
										)}
									</Button>
								</form>
							)}
						</Formik>
					</>
				) : (
					<>
						<Box
							sx={{
								marginBottom: "20px",
								minHeight: "350px",
								display: "flex",
								flexDirection: "column",
								alignItems: "center",
								justifyContent: "center",
							}}
						>
							<Box
								sx={{
									display: "flex",
									alignItems: "center",
									justifyContent: "center",
									marginBottom: "20px",
								}}
							>
								<svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24">
									<path
										fill="#2E7D32"
										d="M12 0C5.373 0 0 5.373 0 12s5.373 12 12 12 12-5.373 12-12S18.627 0 12 0zm-2 17.414L5.586 13 7 11.586l3 3 7-7L19.414 9l-9 9z"
									/>
								</svg>
							</Box>
							<Typography
								variant="h3"
								gutterBottom
								sx={{
									fontWeight: "bold",
									textAlign: "center",
									marginBottom: "20px",
								}}
							>
								Contraseña actualizada
							</Typography>
							<Typography
								variant="body1"
								sx={{
									textAlign: "center",
									color: "#666",
									marginBottom: "20px",
								}}
							>
								¡Listo! Tu contraseña ha sido cambiada. Vuelve a iniciar sesión con tu nueva contraseña.
							</Typography>

							<Button
								variant="contained"
								color="primary"
								sx={{
									width: "60%",
									height: "45px",
									borderRadius: "5px",
									backgroundColor: "#1D74D3",
									fontWeight: "bold",
									"&:hover": {
										backgroundColor: "#155A9C",
									},
								}}
								onClick={() => navigate("/login")}
							>
								Aceptar
							</Button>
						</Box>
					</>
				)}
			</Box>
		</Box>
	);
};