export const tableContent = [
	{
		"field": "costos",
		"name": "Catálogos de Costos",
		"description": "Gestiona todos los catálogos de costos asociados a productos y servicios, optimizando el control financiero y asegurando una evaluación precisa de los gastos.",
		"columns": [
			{
				"field": "id",
				"headerName": "ID",
				"headerAlign": "center",
				"align": "center",
				"flex": 1
			},
			{
				"field": "nombre",
				"headerName": "Nombre",
				"headerAlign": "center",
				"align": "center",
				"flex": 10
			},
			{
				"field": "status",
				"headerName": "Status",
				"headerAlign": "center",
				"align": "center",
				"width": 100
			}
		]
	},
	{
		"field": "cuenta",
		"name": "Catálogos de Cuentas",
		"description": "Organiza y controla las cuentas contables con precisión, asegurando un manejo eficiente de los recursos y balances financieros de la empresa.",
		"columns": [
			{
				"field": "id",
				"headerName": "ID",
				"headerAlign": "center",
				"align": "center",
				"flex": 1
			},
			{
				"field": "nombre",
				"headerName": "Nombre",
				"headerAlign": "center",
				"align": "center",
				"flex": 10
			},
			{
				"field": "status",
				"headerName": "Status",
				"headerAlign": "center",
				"align": "center",
				"width": 100
			}
		]
	},
	{
		"field": "familia",
		"name": "Catálogos de Familias",
		"description": "Agrupa productos y servicios bajo familias para una mejor organización, facilitando la gestión de categorías y subcategorías de inventario.",
		"columns": [
			{
				"field": "id",
				"headerName": "ID",
				"headerAlign": "center",
				"align": "center",
				"flex": 1
			},
			{
				"field": "nombre",
				"headerName": "Nombre",
				"headerAlign": "center",
				"align": "center",
				"flex": 10
			},
			{
				"field": "descripcion",
				"headerName": "Descripción",
				"headerAlign": "center",
				"align": "center",
				"flex": 1
			},
			{
				"field": "status",
				"headerName": "Status",
				"headerAlign": "center",
				"align": "center",
				"width": 100
			}
		]
	},
	{
		"field": "ubicacion",
		"name": "Catálogos de Ubicaciones",
		"description": "Administra y localiza cada activo o producto de manera efectiva, optimizando la logística y el control de inventarios en diversas ubicaciones.",
		"columns": [
			{
				"field": "id",
				"headerName": "ID",
				"headerAlign": "center",
				"align": "center",
				"flex": 1
			},
			{
				"field": "ubicacion",
				"headerName": "Ubicación",
				"headerAlign": "center",
				"align": "center",
				"flex": 5
			},
			{
				"field": "fecha",
				"headerName": "Fecha",
				"headerAlign": "center",
				"align": "center",
				"flex": 5
			},
			{
				"field": "status",
				"headerName": "Status",
				"headerAlign": "center",
				"align": "center",
				"width": 100
			}
		]
	},
	{
		"field": "unidad",
		"name": "Catálogos de Unidades",
		"description": "Define y gestiona las unidades de medida estandarizadas para garantizar consistencia en el control de inventarios y transacciones.",
		"columns": [
			{
				"field": "id",
				"headerName": "ID",
				"headerAlign": "center",
				"align": "center",
				"flex": 1
			},
			{
				"field": "nombre",
				"headerName": "Nombre",
				"headerAlign": "center",
				"align": "center",
				"flex": 10
			},
			{
				"field": "status",
				"headerName": "Status",
				"headerAlign": "center",
				"align": "center",
				"width": 100
			}
		]
	},
	{
		"field": "tipo",
		"name": "Catálogos de Artículos",
		"description": "Clasifica y controla todos los artículos en el sistema, asegurando una gestión eficiente de los productos disponibles.",
		"columns": [
			{
				"field": "id",
				"headerName": "ID",
				"headerAlign": "center",
				"align": "center",
				"flex": 1
			},
			{
				"field": "nombre",
				"headerName": "Nombre",
				"headerAlign": "center",
				"align": "center",
				"flex": 10
			},
			{
				"field": "status",
				"headerName": "Status",
				"headerAlign": "center",
				"align": "center",
				"width": 100
			}
		]
	},
	{
		"field": "facturas",
		"name": "Catálogos de Facturas",
		"description": "Centraliza la información de las facturas emitidas y recibidas, facilitando el control financiero y la auditoría de transacciones.",
		"columns": [
			{
				"field": "id",
				"headerName": "ID",
				"headerAlign": "center",
				"align": "center",
				"flex": 1
			},
			{
				"field": "nombre",
				"headerName": "Nombre",
				"headerAlign": "center",
				"align": "center",
				"flex": 10
			},
			{
				"field": "status",
				"headerName": "Status",
				"headerAlign": "center",
				"align": "center",
				"width": 100
			}
		]
	},
	{
		"field": "movimientos",
		"name": "Catálogos de Movimientos",
		"description": "Registra y organiza los movimientos de inventario y activos para asegurar un seguimiento preciso y eficiente de entradas y salidas.",
		"columns": [
			{
				"field": "id",
				"headerName": "ID",
				"headerAlign": "center",
				"align": "center",
				"flex": 1
			},
			{
				"field": "nombre",
				"headerName": "Nombre",
				"headerAlign": "center",
				"align": "center",
				"flex": 10
			},
			{
				"field": "status",
				"headerName": "Status",
				"headerAlign": "center",
				"align": "center",
				"width": 100
			}
		]
	},
	{
		"field": "polizas",
		"name": "Catálogos de Pólizas",
		"description": "Administra las pólizas del sistema, gestionando símbolos y estatus para un mejor control de las operaciones contables.",
		"columns": [
			{
				"field": "id",
				"headerName": "ID",
				"headerAlign": "center",
				"align": "center",
				"flex": 1
			},
			{
				"field": "nombre",
				"headerName": "Nombre",
				"headerAlign": "center",
				"align": "center",
				"flex": 5
			},
			{
				"field": "simbolo",
				"headerName": "Símbolo",
				"headerAlign": "center",
				"align": "center",
				"flex": 5
			},
			{
				"field": "status",
				"headerName": "Status",
				"headerAlign": "center",
				"align": "center",
				"width": 100
			}
		]
	},
	{
		"field": "usuarios",
		"name": "Catálogos de Usuarios",
		"description": "Gestiona la información de los usuarios del sistema, asegurando un control adecuado de accesos, permisos y descripciones.",
		"columns": [
			{
				"field": "id",
				"headerName": "ID",
				"headerAlign": "center",
				"align": "center",
				"flex": 1
			},
			{
				"field": "nombre",
				"headerName": "Nombre",
				"headerAlign": "center",
				"align": "center",
				"flex": 5
			},
			{
				"field": "descripcion",
				"headerName": "Descripción",
				"headerAlign": "center",
				"align": "center",
				"flex": 5
			},
			{
				"field": "status",
				"headerName": "Status",
				"headerAlign": "center",
				"align": "center",
				"width": 100
			}
		]
	}
];
