import { useState } from 'react';
import { GeneraFormularioCorreo  } from  "../globals/globalForms";
import { recoverFields } from "../../data/forms/login";

import * as yup from "yup";
import { useSnackbar } from 'notistack';

import { UseAppConfig } from '../../system/config';
import { getPostData } from '../../system/getData';

const SendEmail = ({ onCorreoEnviado }) => {
  const { enqueueSnackbar } = useSnackbar();

  const dataConfig = {
      title: "¿Olvidaste tu contraseña?",
      description: "Ingresa el correo electrónico asociado a tu cuenta para continuar.",
      titleBtn: "Recuperar contraseña",
      fields: recoverFields,
  };

  const initValues = {
      correo: '',
  };

  const validateSchemas = yup.object().shape({
      correo: yup.string().email('Correo inválido').required('Inserte su correo electrónico'),
  });

  const { config } = UseAppConfig();

  const handleFormSubmit = async (values) => {
      try {
          const response = await getPostData(`${config.API_BASE_URL}/enviarCorreo`, values);
          if (response && response.message === "Correo enviado exitosamente") {
              enqueueSnackbar("Correo enviado exitosamente", { variant: "success" });
              if (onCorreoEnviado) onCorreoEnviado(values.correo);
          } else {
              enqueueSnackbar(response.message || "Correo no encontrado", { variant: "error" });
          }
      } catch (error) {
          enqueueSnackbar("Error al enviar el correo", { variant: "error" });
      }
  };

  return (
      <GeneraFormularioCorreo
          data={dataConfig}
          initValues={initValues}
          validateSchemas={validateSchemas}
          handleFormSubmit={handleFormSubmit}
      />
  );
};

export default SendEmail;
